import React from "react";

const AdminFooter = () => {
  return (
    <>
      <footer className="fixed bottom-0 overflow-hidden">        
          <div>
            <div className="text-center text-sm py-1 border-t-2 border-gray-300/50 bg-primary text-white">
              @copyright 2024 All rights reserved || Made with ❤️ by Trendy Dialog
            </div>
          </div>
      </footer>
    </>
  );
};

export default AdminFooter;
