import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ViewPackage = () => {
  const { id } = useParams(); // Assuming the ID is passed via the URL
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [packageData, setPackageData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Replace with your API endpoint
    fetch(`${apiUrl}/holidayPackages/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setPackageData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching package data:', error);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!packageData) {
    return <p>Package not found.</p>;
  }

  const {
    name,
    packageType,
    description,
    destinations,
    duration,
    startDate,
    endDate,
    price,
    photos,
    inclusions,
    exclusions,
    itinerary,
    termsAndConditions,
    cancellationPolicy,
    featured,
  } = packageData;

  const handleBookClick = () => {
    navigate(`/booking/${id}`, { state: { packageData: packageData } });
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">{name}</h1>
      <p className="mb-2"><strong>Type:</strong> {packageType}</p>
      <p className="mb-4"><strong>Description:</strong> {description}</p>

      <div className="mb-4">
        <h2 className="text-xl font-semibold">Destinations</h2>
        {destinations.map((dest, index) => (
          <div key={index} className="mb-2">
            <p><strong>Location:</strong> {dest.location}</p>
            <p><strong>Attractions:</strong> {dest.attractions.join(', ')}</p>
          </div>
        ))}
      </div>

      <p className="mb-2">
        <strong>Duration:</strong> {duration.days} Days, {duration.nights} Nights
      </p>
      <p className="mb-2"><strong>Start Date:</strong> {startDate}</p>
      <p className="mb-2"><strong>End Date:</strong> {endDate}</p>

      <div className="mb-4">
        <h2 className="text-xl font-semibold">Pricing</h2>
        <p><strong>Adult:</strong> {price.currency} {price.adult}</p>
        <p><strong>Child:</strong> {price.currency} {price.child}</p>
      </div>

      {photos?.length > 0 && (
        <div className="mb-4">
          <h2 className="text-xl font-semibold">Photos</h2>
          <div className="grid grid-cols-4 gap-2">
            {photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`Photo ${index + 1}`}
                className="h-20 w-20 object-cover rounded-md"
              />
            ))}
          </div>
        </div>
      )}

      <div className="mb-4">
        <h2 className="text-xl font-semibold">Inclusions</h2>
        <ul>
          {inclusions.map((item, index) => (
            <li key={index}>- {item}</li>
          ))}
        </ul>
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-semibold">Exclusions</h2>
        <ul>
          {exclusions.map((item, index) => (
            <li key={index}>- {item}</li>
          ))}
        </ul>
      </div>

      <div className="mb-4">
        <h2 className="text-xl font-semibold">Itinerary</h2>
        {itinerary.map((day, index) => (
          <div key={index} className="border p-4 rounded-lg mb-2">
            <h3 className="font-bold">Day {day.day}: {day.title}</h3>
            <p>{day.description}</p>
            <p><strong>Meals:</strong> {Object.keys(day.meals).filter((meal) => day.meals[meal]).join(', ')}</p>
            <p><strong>Accommodation:</strong> {day.accommodation.name} ({day.accommodation.type})</p>
            <p><strong>Transportation:</strong> {day.transportation.type} - {day.transportation.details}</p>
          </div>
        ))}
      </div>

      <p className="mb-4"><strong>Terms and Conditions:</strong> {termsAndConditions}</p>
      <p className="mb-4"><strong>Cancellation Policy:</strong> {cancellationPolicy}</p>
      <p className="mb-4"><strong>Featured:</strong> {featured ? 'Yes' : 'No'}</p>
      <button
        onClick={handleBookClick}
        className="mt-6 px-4 py-2 bg-blue-600 text-white font-bold rounded hover:bg-blue-700"
      >
        Book
      </button>
    </div>
  );
};

export default ViewPackage;