import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  FaDemocrat,
  FaHotel,
  FaBed,
  FaList,
  FaChartBar,
  FaCog,
  FaTags,
  FaCalendarAlt,
  FaGlobeAfrica,
} from 'react-icons/fa';
import { MdMeetingRoom, MdOutlineDashboardCustomize } from "react-icons/md";
import { useSidebar } from '../../context/SidebarContext';

const menuItems = [
  { icon: MdOutlineDashboardCustomize, label: 'Dashboard', path: '/dashboard' },
  { icon: FaHotel, label: 'Hotel', path: '/hotel/list' },
];

const hotelSpecificMenuItems = [
  { icon: FaList, label: 'Amenities', path: '/amenities' },
  { icon: FaBed, label: 'Room Types', path: '/roomtypes' },
  { icon: FaTags, label: 'Rates & Types', path: '/ratetypes' },
  { icon: FaCalendarAlt, label: 'Activities', path: '/activities' },
  // { icon: FaTags, label: 'Rates', path: '/rates' },  
  // { icon: MdMeetingRoom, label: 'Rooms', path: '/rooms' },
  { icon: FaGlobeAfrica, label: 'Holiday Packages', path: '/holiday-packages' },
  // { icon: FaGlobeAfrica, label: 'Admin Packages', path: '/packages/create' },
  // { icon: FaCog, label: 'Policies', path: '/policies' },
  // { icon: FaChartBar, label: 'Analytics', path: '/analytics' },
];

export function AdminSidebar() {
  const { isOpen, toggle } = useSidebar();
  const location = useLocation();

  // Define routes where hotel-specific menu items should NOT be shown
  const noHotelSpecificRoutes = ['/dashboard', '/hotel/list'];

  // Determine if hotel-specific menu items should be shown
  const showHotelSpecificItems = !noHotelSpecificRoutes.includes(location.pathname);

  return (
    <div
      className={`bg-[#0287a8] text-white h-screen ${
        isOpen ? 'w-64' : 'w-16'
      } fixed pt-16 sm:pt-24 transition-all duration-300`}
    >
      <div className="flex items-center justify-between p-4">
        <h3 className={`text-lg font-bold whitespace-nowrap ${isOpen ? 'block' : 'hidden'}`}>
          Admin Portal
        </h3>
        <button onClick={toggle} className="text-white">
          {isOpen ? '←' : '→'}
        </button>
      </div>
      <nav>
        <ul>
          {menuItems.map((item) => (
            <li key={item.path}>
              <Link
                to={item.path}
                className={`flex items-center px-4 py-2 hover:bg-gray-700 ${
                  location.pathname === item.path ? 'bg-gray-700' : ''
                }`}
              >
                <item.icon className="text-xl" />
                <span className={`ml-4 text-sm ${isOpen ? 'block' : 'hidden'}`}>{item.label}</span>
              </Link>
            </li>
          ))}

          {showHotelSpecificItems &&
            hotelSpecificMenuItems.map((item) => (
              <li key={item.path}>
                <Link
                  to={item.path}
                  className={`flex items-center px-4 py-2 hover:bg-gray-700 ${
                    location.pathname === item.path ? 'bg-gray-700' : ''
                  }`}
                >
                  <item.icon className="text-xl" />
                  <span className={`ml-4 text-sm ${isOpen ? 'block' : 'hidden'}`}>{item.label}</span>
                </Link>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
}