import React from "react";
import { useParams } from "react-router-dom";
import EditPackageForm from "../../components/admin/EditPackageForm";

const EditPackage = () => {
  const { id } = useParams();

  return (
    <div>
      <EditPackageForm packageId={id} />
    </div>
  );
};

export default EditPackage;
