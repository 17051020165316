import React from "react";
import { useParams } from "react-router-dom";
import PackageList from "../../components/admin/PackageList";

const PackageTable = () => {
  const { id } = useParams();

  return (
    <div className="admin-dashboard">
      <PackageList/>
    </div>
  );
};

export default PackageTable;