import React from "react";
import HotelList from "../../components/admin/HotelList";

const Hotel = () => {

  return (
    <div>
      <HotelList />
    </div>
  );
};

export default Hotel;