import React, { useState } from "react";
import ActivityForm from "../../components/admin/ActivityForm";

const ActivitiesManager = () => {
    return (
      <div>
        <ActivityForm/>
      </div>
    );
  };
  
  export default ActivitiesManager;
  