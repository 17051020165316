import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { IoPricetags } from "react-icons/io5";
import { MdDescription, MdAccessTime, MdPeople, MdPriceCheck } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const apiUrl = process.env.REACT_APP_API_URL;

const ActivityForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    duration: "",
    maxParticipants: "",
    price: "",
  });
  const [activities, setActivities] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [viewingActivity, setViewingActivity] = useState(null); // For modal view
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("access_token");
  const hotelId = localStorage.getItem("hotelId");

  // Fetch all activities on component mount
  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/activities`);
      setActivities(res.data);
    } catch (err) {
      console.error("Error fetching activities:", err);
      toast.error("Failed to fetch activities.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.duration || !formData.maxParticipants) {
      toast.warn("Please fill in all required fields.");
      return;
    }

    try {
      if (editingIndex !== null) {
        // Update an existing activity
        const activityToUpdate = activities[editingIndex];
        await axios.put(`${apiUrl}/activities/${activityToUpdate._id}`, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success("Activity updated successfully!");
      } else {
        // Create a new activity
        const payload = { ...formData, hotel: hotelId };
        const res = await axios.post(`${apiUrl}/activities`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setActivities([...activities, res.data]);
        toast.success("Activity created successfully!");
      }

      fetchActivities(); // Refresh the list after changes
      setEditingIndex(null);
      setFormData({
        name: "",
        description: "",
        duration: "",
        maxParticipants: "",
        price: "",
      });
    } catch (err) {
      console.error("Error submitting form:", err);
      toast.error("An error occurred while processing the request.");
    }
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setFormData(activities[index]); // Pre-fill the form with the activity's data
  };

  const handleDelete = async (index) => {
    if (window.confirm("Are you sure you want to delete this activity?")) {
      try {
        const activityToDelete = activities[index];
        await axios.delete(`${apiUrl}/activities/${activityToDelete._id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success("Activity deleted successfully!");
        fetchActivities();
      } catch (err) {
        console.error("Error deleting activity:", err);
        toast.error("An error occurred while trying to delete the activity.");
      }
    }
  };

  const handleView = (index) => {
    setViewingActivity(activities[index]);
  };

  const handleCloseView = () => {
    setViewingActivity(null);
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <ToastContainer 
        autoClose={1000}
        newestOnTop
        closeOnClick
        pauseOnHover
      />
      <h2 className="text-2xl font-bold mb-4">
        {editingIndex !== null ? "Edit Activity" : "Add Activity"}
      </h2>

      {/* Form */}
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="name" className="block font-medium text-gray-700 flex items-center gap-2">
              <MdDescription /> Activity Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter activity name"
              required
            />
          </div>
          <div>
            <label htmlFor="duration" className="block font-medium text-gray-700 flex items-center gap-2">
              <MdAccessTime /> Duration
            </label>
            <input
              type="text"
              id="duration"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter duration (e.g., 2 hours)"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="maxParticipants" className="block font-medium text-gray-700 flex items-center gap-2">
              <MdPeople /> Max Participants
            </label>
            <input
              type="number"
              id="maxParticipants"
              name="maxParticipants"
              value={formData.maxParticipants}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter maximum participants"
              required
            />
          </div>
          <div>
            <label htmlFor="price" className="block font-medium text-gray-700 flex items-center gap-2">
              <IoPricetags /> Price
            </label>
            <input
              type="text"
              id="price"
              name="price"
              value={formData.price}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter price per person or per group"
            />
          </div>
        </div>

        <div>
          <label htmlFor="description" className="block font-medium text-gray-700 flex items-center gap-2">
            <MdDescription /> Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-lg p-2 outline-none"
            placeholder="Enter description (optional)"
            rows="4"
          ></textarea>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          {editingIndex !== null ? "Update Activity" : "Submit"}
        </button>
      </form>

      {/* Modal for Viewing */}
      {viewingActivity && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full border border-gray-300">
            <h3 className="text-2xl font-bold mb-6 text-center border-b pb-4">Activity Details</h3>
        
            <div className="grid grid-cols-2 gap-4">
              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>Name:</strong>
                </p>
                <p className="text-gray-600">{viewingActivity.name}</p>
              </div>
        
              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>Duration:</strong>
                </p>
                <p className="text-gray-600">{viewingActivity.duration}</p>
              </div>
        
              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>Max Participants:</strong>
                </p>
                <p className="text-gray-600">{viewingActivity.maxParticipants}</p>
              </div>
        
              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>Price:</strong>
                </p>
                <p className="text-gray-600">{viewingActivity.price}</p>
              </div>
            </div>
        
            <div className="mt-6 border p-4 rounded-lg">
              <p className="text-gray-700 font-medium">
                <strong>Description:</strong>
              </p>
              <p className="text-gray-600">{viewingActivity.description}</p>
            </div>
        
            <div className="mt-8 flex justify-end">
              <button
                onClick={handleCloseView}
                className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition border border-red-700"
              >
                Close
              </button>
            </div>
          </div>
        </div>      
      )}

      {/* List */}
      <div className="mt-6">
        <h3 className="text-xl font-bold mb-4">Activities List</h3>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2">Name</th>
                <th className="border border-gray-300 px-4 py-2">Duration</th>
                <th className="border border-gray-300 px-4 py-2">Max Participants</th>
                <th className="border border-gray-300 px-4 py-2">Price</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {activities.map((activity, index) => (
                <tr key={activity._id}>
                  <td className="border border-gray-300 px-4 py-2">{activity.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{activity.duration}</td>
                  <td className="border border-gray-300 px-4 py-2">{activity.maxParticipants}</td>
                  <td className="border border-gray-300 px-4 py-2">{activity.price}</td>
                  <td className="border border-gray-300 px-4 py-3 flex justify-around">
                    <button onClick={() => handleView(index)} className="text-blue-500 hover:text-blue-700">
                      <FaEye />
                    </button>
                    <button onClick={() => handleEdit(index)} className="text-green-500 hover:text-green-700">
                      <FaEdit />
                    </button>
                    <button onClick={() => handleDelete(index)} className="text-red-500 hover:text-red-700">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default ActivityForm;
