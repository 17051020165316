import React, { useState, useEffect } from "react";
import { FaRegBuilding, FaEnvelope, FaPhone, FaMapMarkerAlt, FaStar, FaImages } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const HolidayPackageForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    packageType: "",
    destinations: [{ location: "", attractions: [""] }],
    duration: { days: 0, nights: 0 },
    startDate: "",
    endDate: "",
    price: { adult: 0, child: 0, currency: "ZAR" },
    inclusions: [],
    exclusions: [],
    itinerary: [],
    termsAndConditions: "",
    cancellationPolicy: "",
    featured: true,
    reviews: [],
  });

  const [newInclusion, setNewInclusion] = useState("");
  const [newExclusion, setNewExclusion] = useState("");
  const [newPhoto, setNewPhoto] = useState("");

  const token = localStorage.getItem("access_token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const apiUrl = process.env.REACT_APP_API_URL;

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
    }

    if (!formData.packageType) {
      newErrors.packageType = "Package Type is required.";
    }

    if (!formData.description.trim()) {
      newErrors.description = "Description is required.";
    }

    if (formData.destinations.length === 0) {
      newErrors.destinations = "At least one destination is required.";
    } else {
      formData.destinations.forEach((destination, index) => {
        if (!destination.location.trim()) {
          newErrors[`destination_${index}_location`] = `Destination ${index + 1} location is required.`;
        }

        if (destination.attractions.length === 0) {
          newErrors[`destination_${index}_attractions`] = `Destination ${index + 1} must have at least one attraction.`;
        } else {
          destination.attractions.forEach((attraction, attrIndex) => {
            if (!attraction.trim()) {
              newErrors[`destination_${index}_attraction_${attrIndex}`] = `Attraction ${attrIndex + 1} for Destination ${index + 1} is required.`;
            }
          });
        }
      });
    }

    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (id) {
      axios.get(`${apiUrl}/holiday-packages/${id}`).then((res) => setFormData(res.data));
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 8);
    const photoURLs = files.map((file) => URL.createObjectURL(file));
    setFormData({ ...formData, photos: photoURLs });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const originalAuthHeader = axios.defaults.headers.common["Authorization"];
      delete axios.defaults.headers.common["Authorization"];

      try {
        // Upload photos to Cloudinary
        const photoUrls = await Promise.all(
          formData.photos.map(async (photo) => {
            const fileBlob = await fetch(photo).then((res) => res.blob());
            const formData = new FormData();
            formData.append("file", fileBlob);
            formData.append("upload_preset", "upload");

            const uploadRes = await axios.post(
              "https://api.cloudinary.com/v1_1/dn8636eu9/image/upload",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "X-Requested-With": "XMLHttpRequest",
                },
              }
            );

            return uploadRes.data.url;
          })
        );

        // Restore Authorization header after uploads
        axios.defaults.headers.common["Authorization"] = originalAuthHeader;

        // Create payload with updated photo URLs
        const payload = { ...formData, photos: photoUrls };

        // Send payload to your API
        const response = await axios.post(
          `${apiUrl}/holidayPackages`,
          payload,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        toast.success("Travel package created successfully!");
        navigate("/dashboard");
      } catch (err) {
        console.error(err);
        toast.error("Failed to create travel package.");
      }
    }
  };

  const handleAddInclusion = () => {
    if (newInclusion.trim()) {
      setFormData({ ...formData, inclusions: [...formData.inclusions, newInclusion] });
      setNewInclusion("");
    }
  };

  const handleRemoveInclusion = (index) => {
    const updatedInclusions = formData.inclusions.filter((_, i) => i !== index);
    setFormData({ ...formData, inclusions: updatedInclusions });
  };

  const handleAddExclusion = () => {
    if (newExclusion.trim()) {
      setFormData({ ...formData, exclusions: [...formData.exclusions, newExclusion] });
      setNewExclusion("");
    }
  };

  const handleRemoveExclusion = (index) => {
    const updatedExclusions = formData.exclusions.filter((_, i) => i !== index);
    setFormData({ ...formData, exclusions: updatedExclusions });
  };

  const handleItineraryChange = (index, key, value) => {
    const updatedItinerary = [...formData.itinerary];
    updatedItinerary[index][key] = value;
    setFormData({ ...formData, itinerary: updatedItinerary });
  };

  const addItineraryDay = () => {
    setFormData({
      ...formData,
      itinerary: [
        ...formData.itinerary,
        {
          day: formData.itinerary.length + 1,
          title: "",
          description: "",
          meals: { breakfast: false, lunch: false, dinner: false },
          accommodation: { name: "", type: "", rating: null },
          transportation: { type: "", details: "" },
        },
      ],
    });
  };

  const removeItineraryDay = (index) => {
    const updatedItinerary = formData.itinerary.filter((_, i) => i !== index);
    setFormData({ ...formData, itinerary: updatedItinerary });
  };

  return (
    <div className="p-6">
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-4">{id ? "Edit Package" : "Create Package"}</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* General Details */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block font-medium">Name</label>
            <input
              type="text"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </div>
          {/* Package Type */}
          <div>
            <label className="block font-medium">Package Type</label>
            <select
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={formData.packageType}
              onChange={(e) => setFormData({ ...formData, packageType: e.target.value })}
            >
              <option value="">Select Type</option>
              {["Adventure", "Honeymoon", "Family", "Solo", "Group", "Luxury", "Customized", "Cruise"].map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <label className="block font-medium">Description</label>
          <textarea
            className="border border-gray-300 rounded-lg p-2 w-full"
            value={formData.description}
            onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          />
        </div> 

        {/* Destinations */}
        <div>
          <label className="block font-medium">Destinations</label>
          {formData.destinations.map((destination, index) => (
            <div key={index} className="mb-2 border p-2 rounded-lg">
              <input
                type="text"
                className="border rounded-lg p-2 w-full mb-2"
                placeholder="Location/Country/Province/State"
                value={destination.location}
                onChange={(e) => {
                  const updatedDestinations = [...formData.destinations];
                  updatedDestinations[index].location = e.target.value;
                  setFormData({ ...formData, destinations: updatedDestinations });
                }}
              />
              {destination.attractions.map((attraction, attrIndex) => (
                <div key={attrIndex} className="flex items-center mb-1">
                  <input
                    type="text"
                    className="border rounded-lg p-2 w-full"
                    placeholder="Attraction"
                    value={attraction}
                    onChange={(e) => {
                      const updatedAttractions = [...destination.attractions];
                      updatedAttractions[attrIndex] = e.target.value;
                      const updatedDestinations = [...formData.destinations];
                      updatedDestinations[index].attractions = updatedAttractions;
                      setFormData({ ...formData, destinations: updatedDestinations });
                    }}
                  />
                  <button
                    type="button"
                    className="text-red-500 ml-2 hover:underline"
                    onClick={() => {
                      const updatedAttractions = destination.attractions.filter((_, i) => i !== attrIndex);
                      const updatedDestinations = [...formData.destinations];
                      updatedDestinations[index].attractions = updatedAttractions;
                      setFormData({ ...formData, destinations: updatedDestinations });
                    }}
                  >
                    Delete
                  </button>
                </div>
              ))}
              <button
                type="button"
                className="text-blue-500 hover:underline"
                onClick={() => {
                  const updatedDestinations = [...formData.destinations];
                  updatedDestinations[index].attractions.push("");
                  setFormData({ ...formData, destinations: updatedDestinations });
                }}
              >
                Add Attraction
              </button>
              <button
                type="button"
                className="text-red-500 hover:underline ml-4"
                onClick={() => {
                  const updatedDestinations = formData.destinations.filter((_, i) => i !== index);
                  setFormData({ ...formData, destinations: updatedDestinations });
                }}
              >
                Delete Destination
              </button>
            </div>
          ))}
          <button
            type="button"
            className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
            onClick={() =>
              setFormData({
                ...formData,
                destinations: [...formData.destinations, { location: "", attractions: [""] }],
              })
            }
          >
            Add Destination
          </button>
        </div>

        {/* Duration */}
        <div>
          <label className="block font-medium">Duration (Days & Nights)</label>
          <div className="flex space-x-4">
            <input
              type="number"
              className="border border-gray-300 rounded-lg p-2 w-full"
              placeholder="Days"
              value={formData.duration.days}
              onChange={(e) =>
                setFormData({ ...formData, duration: { ...formData.duration, days: e.target.value } })
              }
            />
            <input
              type="number"
              className="border border-gray-300 rounded-lg p-2 w-full"
              placeholder="Nights"
              value={formData.duration.nights}
              onChange={(e) =>
                setFormData({ ...formData, duration: { ...formData.duration, nights: e.target.value } })
              }
            />
          </div>
        </div>

        {/* Start and End Date */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block font-medium">Start Date</label>
            <input
              type="date"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={formData.startDate}
              onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
            />
          </div>
          <div>
            <label className="block font-medium">End Date</label>
            <input
              type="date"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={formData.endDate}
              onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
            />
          </div>
        </div>

        {/* Price */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div>
            <label className="block font-medium">Price (Adult)</label>
            <input
              type="number"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={formData.price.adult}
              onChange={(e) =>
                setFormData({ ...formData, price: { ...formData.price, adult: e.target.value } })
              }
            />
          </div>

          <div>
            <label className="block font-medium">Price (Child)</label>
            <input
              type="number"
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={formData.price.child}
              onChange={(e) =>
                setFormData({ ...formData, price: { ...formData.price, child: e.target.value } })
              }
            />
          </div>

          <div>
            <label className="block font-medium">Currency</label>
            <select
              className="border border-gray-300 rounded-lg p-2 w-full"
              value={formData.price.currency}
              onChange={(e) =>
                setFormData({ ...formData, price: { ...formData.price, currency: e.target.value } })
              }
            >
              <option value="ZAR">ZAR</option>
              <option value="USD">USD</option>
            </select>
          </div>
        </div>
        {/* Photos */}
        <div>
          <label htmlFor="photos" className="block font-medium text-gray-700">
            Photos (Max 8)
          </label>
          <div className="flex items-center border border-gray-300 rounded-lg p-2">
            <FaImages className="text-gray-500 mr-2" />
            <input
              type="file"
              id="photos"
              name="photos"
              onChange={handlePhotoChange}
              className="flex-1 outline-none"
              multiple
              accept="image/*"
            />
          </div>
          {Array.isArray(formData.photos) && formData.photos.length > 0 && (
            <div className="mt-2 grid grid-cols-4 gap-2">
              {formData.photos.map((photo, index) => (
                <img
                  key={index}
                  src={photo}
                  alt={`Preview ${index + 1}`}
                  className="h-20 w-20 object-cover rounded-md"
                />
              ))}
            </div>
          )}
        </div>

        {/* Inclusions */}
        <div>
          <label className="block font-medium">Inclusions</label>
          <div className="flex gap-2">
            <input
              type="text"
              value={newInclusion}
              onChange={(e) => setNewInclusion(e.target.value)}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Add Inclusion"
            />
            <button
              type="button"
              onClick={handleAddInclusion}
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
            >
              Add
            </button>
          </div>
          <ul className="mt-2 space-y-1">
            {formData.inclusions.map((inclusion, index) => (
              <li key={index} className="flex justify-between items-center">
                <span>{inclusion}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveInclusion(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Exclusions */}
        <div>
          <label className="block font-medium">Exclusions</label>
          <div className="flex gap-2">
            <input
              type="text"
              value={newExclusion}
              onChange={(e) => setNewExclusion(e.target.value)}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Add Exclusion"
            />
            <button
              type="button"
              onClick={handleAddExclusion}
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
            >
              Add
            </button>
          </div>
          <ul className="mt-2 space-y-1">
            {formData.exclusions.map((exclusion, index) => (
              <li key={index} className="flex justify-between items-center">
                <span>{exclusion}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveExclusion(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Itinerary */}
        <div>
          <label className="block font-medium">Itinerary</label>
          {Array.isArray(formData.itinerary) && formData.itinerary.map((day, index) => (
            <div key={index} className="mb-4 border p-4 rounded-lg">
              <div>
                <label className="block font-medium">Day {day.day}</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full mb-2"
                  placeholder="Title"
                  value={day.title}
                  onChange={(e) => handleItineraryChange(index, "title", e.target.value)}
                />
                <textarea
                  className="border rounded-lg p-2 w-full mb-2"
                  placeholder="Description"
                  value={day.description}
                  onChange={(e) => handleItineraryChange(index, "description", e.target.value)}
                />
                <div>
                  <label className="block font-medium">Meals</label>
                  <div className="flex items-center space-x-4">
                    {["breakfast", "lunch", "dinner"].map((meal) => (
                      <label key={meal} className="flex items-center">
                        <input
                          type="checkbox"
                          checked={day.meals[meal]}
                          onChange={(e) =>
                            handleItineraryChange(index, "meals", {
                              ...day.meals,
                              [meal]: e.target.checked,
                            })
                          }
                        />
                        <span className="ml-2">{meal.charAt(0).toUpperCase() + meal.slice(1)}</span>
                      </label>
                    ))}
                  </div>
                </div>
                <div>
                  <label className="block font-medium">Accommodation</label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-lg p-2 w-full mb-2"
                    placeholder="Accommodation Name"
                    value={day.accommodation.name}
                    onChange={(e) =>
                      handleItineraryChange(index, "accommodation", {
                        ...day.accommodation,
                        name: e.target.value,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="border border-gray-300 rounded-lg p-2 w-full mb-2"
                    placeholder="Accommodation Type"
                    value={day.accommodation.type}
                    onChange={(e) =>
                      handleItineraryChange(index, "accommodation", {
                        ...day.accommodation,
                        type: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label className="block font-medium">Transportation</label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-lg p-2 w-full mb-2"
                    placeholder="Transportation Type"
                    value={day.transportation.type}
                    onChange={(e) =>
                      handleItineraryChange(index, "transportation", {
                        ...day.transportation,
                        type: e.target.value,
                      })
                    }
                  />
                  <input
                    type="text"
                    className="border border-gray-300 rounded-lg p-2 w-full mb-2"
                    placeholder="Transportation Details"
                    value={day.transportation.details}
                    onChange={(e) =>
                      handleItineraryChange(index, "transportation", {
                        ...day.transportation,
                        details: e.target.value,
                      })
                    }
                  />
                </div>
                <button
                  type="button"
                  className="text-red-600 hover:underline"
                  onClick={() => removeItineraryDay(index)}
                >
                  Remove Day
                </button>
              </div>
            </div>
          ))}
          <button
            type="button"
            className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700"
            onClick={addItineraryDay}
          >
            Add Itinerary Day
          </button>
        </div>        

        {/* Terms and Conditions */}
        <div>
          <label className="block font-medium">Terms and Conditions</label>
          <textarea
            rows={3}
            className="border border-gray-300 rounded-lg p-2 w-full"
            value={formData.termsAndConditions}
            onChange={(e) => setFormData({ ...formData, termsAndConditions: e.target.value })}
          />
        </div>

        {/* Cancellation Policy */}
        <div>
          <label className="block font-medium">Cancellation Policy</label>
          <textarea
            rows={3}
            className="border border-gray-300 rounded-lg p-2 w-full"
            value={formData.cancellationPolicy}
            onChange={(e) => setFormData({ ...formData, cancellationPolicy: e.target.value })}
          />
        </div>

        {/* Featured */}
        <div>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={formData.featured}
              onChange={() => setFormData({ ...formData, featured: !formData.featured })}
            />
            Featured
          </label>
        </div>

        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
        >
          {id ? "Update Package" : "Create Package"}
        </button>
      </form>
    </div>
  );
};

export default HolidayPackageForm;