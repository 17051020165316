import React, { useState } from "react";
import HolidayPackageForm from "../../components/admin/HolidayPackageForm";

const HolidayPackagesManager = () => {
    return (
      <div>
        <HolidayPackageForm/>
      </div>
    );
  };
  
  export default HolidayPackagesManager;
  