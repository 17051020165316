import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import { MdAttachMoney, MdDateRange } from "react-icons/md";
import { IoPricetags } from "react-icons/io5";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const apiUrl = "http://localhost:8800/api";

const RateForm = () => {
  const [formData, setFormData] = useState({
    roomType: "",
    date: "",
    stoRateSingle: "",
    rackRateSingle: "",
    stoRateDouble: "",
    rackRateDouble: "",
    stoRateGroup: "",
    rackRateGroup: "",
  });
  const [rates, setRates] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [rateTypes, setRateTypes] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [viewRate, setViewRate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hotelName, setHotelName] = useState("");
  const token = localStorage.getItem("access_token");
  const hotelId = localStorage.getItem("hotelId");
  const selectedRoomType = '';

  const getRoomTypeName = (id) => {
    const roomType = roomTypes.find((type) => type.id === id);
    console.log(roomType);
    return roomType ? roomType.name : "Unknown Room Type";
  };

  // Fetch room types with rates when the component mounts
  const fetchRoomTypesWithRates = async () => {
    try {
      if (!hotelId) {
        toast.error("Hotel ID not found in localStorage.");
        return;
      }

      const response = await axios.get(`${apiUrl}/roomtypesrates?hotelId=${hotelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Room types with rates:", response.data);
      setRoomTypes(response.data);
    } catch (error) {
      toast.error("Failed to fetch room types.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch room types when the component mounts
  const fetchRoomTypes = async () => {
    try {
      if (!hotelId) {
        toast.error("Hotel ID not found in localStorage.");
        return;
      }

      const response = await axios.get(`${apiUrl}/roomTypes?hotelId=${hotelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRoomTypes(response.data);
    } catch (error) {
      toast.error("Failed to fetch room types.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRateTypes = async () => {
    try {
      if (!hotelId) {
        toast.error("Hotel ID not found in localStorage.");
        return;
      }

      const response = await axios.get(`${apiUrl}/rateTypes?roomType=${selectedRoomType}&hotelId=${hotelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setRateTypes(response.data);
    } catch (error) {
      toast.error("Failed to fetch room types.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch rates from the backend
  const fetchRates = async () => {
    try {
      if (!hotelId) {
        toast.error("Hotel ID not found in localStorage.");
        return;
      }

      const response = await axios.get(`${apiUrl}/rates?hotelId=${hotelId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      // Retry mechanism to resolve room type names
      const maxRetries = 10;
      const retryDelay = 500;

      const resolveRoomTypes = async () => {
        let attempts = 0;

        // Mapping function to resolve room types
        const mapRoomTypes = () => {
          return response.data
            .map((rate) => {
              const room = roomTypes.find((room) => room._id === rate.roomType);
              return room ? { ...rate, roomType: room.name } : null;
            })
            .filter((rate) => rate !== null);
        };

        let updatedRates = mapRoomTypes();

        // Retry until all room types are resolved or max retries reached
        while (updatedRates.length !== response.data.length && attempts < maxRetries) {
          await new Promise((resolve) => setTimeout(resolve, retryDelay)); // Wait before retrying
          attempts++;
          updatedRates = mapRoomTypes();
        }

        if (updatedRates.length !== response.data.length) {
          console.warn("Some room types could not be resolved after retries.");
        }

        return updatedRates;
      };

      const updatedRates = await resolveRoomTypes();
      setRates(updatedRates);
    } catch (error) {
      toast.error("Failed to fetch rates.");
      console.error(error);
    }
  };


  useEffect(() => {

    if (hotelId) {
      // Assuming `fetchHotel` is a function to fetch hotel details based on hotelId
      fetch(`/api/hotels/${hotelId}`)
        .then((res) => res.json())
        .then((data) => {
          setHotelName(data.name);
          setFormData((prevFormData) => ({
            ...prevFormData,
            hotel: hotelId, 
          }));
        })
        .catch((error) => console.error("Error fetching hotel:", error));
    }

    fetchRoomTypesWithRates();
    fetchRoomTypes();
    fetchRates();
  }, [hotelId, token, setFormData]);
  
  if (loading) {
    return <div>Loading...</div>;
  } 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.roomType || !formData.date || !formData.stoRateDouble || !formData.rackRateDouble) {
      toast.warn("Please fill in all required fields.");
      return;
    }

    try {
      if (isEditing && editingIndex !== null) {
        // Update an existing rate
        const rateToUpdate = rates[editingIndex];
        console.log("Payload", rateToUpdate);
        await axios.put(`${apiUrl}/rates/${rateToUpdate._id}`, formData, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        toast.success("Rate updated successfully!");
      } else {
        // Create a new rate
        const payload = { ...formData, hotel: hotelId };        
        const response = await axios.post(`${apiUrl}/rates`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setRates([...rates, response.data]);
        toast.success("Rate added successfully!");
      }

      fetchRates(); // Refresh the list after changes
      resetForm();
    } catch (err) {
      console.error("Error submitting rate:", err);
      toast.error("An error occurred while processing the request.");
    }
  };

  const handleEdit = (index) => {
    setIsEditing(true);
    const rateToEdit = rates[index];
    setEditingIndex(index);

    // Pre-fill the form with the rate's data
    setFormData({
      ...rateToEdit,
      date: formatDateForInput(rateToEdit.date),
      roomType: roomTypes.find((room) => room.name === rateToEdit.roomType)?._id || "", // Get the ID from the name
    });
  };

  const handleDelete = async (index) => {
    if (window.confirm("Are you sure you want to delete this rate?")) {
      try {
        const rateToDelete = rates[index];
        await axios.delete(`${apiUrl}/rates/${rateToDelete._id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Rate deleted successfully!");
        fetchRates();
      } catch (err) {
        console.error("Error deleting rate:", err);
        toast.error("An error occurred while trying to delete the rate.");
      }
    }
  };

  const handleView = (index) => {
    setViewRate(rates[index]);
  };

  const closeModal = () => {
    setViewRate(null);
  };

  const resetForm = () => {
    setFormData({
      roomType: "",
      date: "",
      stoRateSingle: "",
      rackRateSingle: "",
      stoRateDouble: "",
      rackRateDouble: "",
      stoRateGroup: "",
      rackRateGroup: "",
    });
    setIsEditing(false);
    setEditingIndex(null);
  };

  // Helper function to format the date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0]; // Extract "yyyy-MM-dd" part
  };


  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <ToastContainer />
      <h2 className="text-2xl font-bold mb-4">{isEditing ? "Edit Rate" : "Add Rate"}</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="hotel" className="block font-medium text-gray-700">
              Hotel
            </label>
            <input
              type="text"
              id="hotel"
              name="hotel"
              value={hotelName}
              className="w-full border rounded-lg p-2 outline-none bg-gray-100"
              disabled
            />
          </div>

          <div>
            <label htmlFor="roomType" className="block font-medium text-gray-700">
              Room Type
            </label>
            <select
              id="roomType"
              name="roomType"
              value={formData.roomType}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              required
            >
              <option value="" disabled>
                Select a room type
              </option>
              {roomTypes.map((room) => (
                <option key={room._id} value={room._id}>
                  {room.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="rateType" className="block font-medium text-gray-700">
              Rate Type
            </label>
            <select
              id="rateType"
              name="rateType"
              value={formData.rateType}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              required
            >
              <option value="" disabled>
                Select a rate type
              </option>
              {rateTypes.map((rate) => (
                <option key={rate._id} value={rate._id}>
                  {rate.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="date" className="block font-medium text-gray-700 flex items-center gap-2">
              <MdDateRange /> Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="roomType" className="block font-medium text-gray-700">
              Room Type
            </label>
            <select
              id="roomType"
              name="roomType"
              value={formData.roomType}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              required
            >
              <option value="" disabled>
                Select a room type
              </option>
              {roomTypes.map((room) => (
                <option key={room._id} value={room._id}>
                  {room.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label htmlFor="date" className="block font-medium text-gray-700 flex items-center gap-2">
              <MdDateRange /> Start Date
            </label>
            <input
              type="date"
              id="date"
              name="date"
              value={formData.date}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              required
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="stoRateSingle" className="block font-medium text-gray-700 flex items-center gap-2">
              <IoPricetags /> STO Rate Single
            </label>
            <input
              type="number"
              id="stoRateSingle"
              name="stoRateSingle"
              value={formData.stoRateSingle}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Enter STO rate"
              required
            />
          </div>

          <div>
            <label htmlFor="rackRateSingle" className="block font-medium text-gray-700 flex items-center gap-2">
              <IoPricetags /> Rack Rate Single
            </label>
            <input
              type="number"
              id="rackRateSingle"
              name="rackRateSingle"
              value={formData.rackRateSingle}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Enter rack rate"
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="stoRateDouble" className="block font-medium text-gray-700 flex items-center gap-2">
              <IoPricetags /> STO Rate Double
            </label>
            <input
              type="number"
              id="stoRateDouble"
              name="stoRateDouble"
              value={formData.stoRateDouble}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Enter STO rate"
              required
            />
          </div>

          <div>
            <label htmlFor="rackRateDouble" className="block font-medium text-gray-700 flex items-center gap-2">
              <IoPricetags /> Rack Rate Double
            </label>
            <input
              type="number"
              id="rackRateDouble"
              name="rackRateDouble"
              value={formData.rackRateDouble}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Enter rack rate"
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="stoRateGroup" className="block font-medium text-gray-700 flex items-center gap-2">
              <IoPricetags /> STO Rate Group
            </label>
            <input
              type="number"
              id="stoRateGroup"
              name="stoRateGroup"
              value={formData.stoRateGroup}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Enter STO rate"
              required
            />
          </div>

          <div>
            <label htmlFor="rackRateGroup" className="block font-medium text-gray-700 flex items-center gap-2">
              <IoPricetags /> Rack Rate Group
            </label>
            <input
              type="number"
              id="rackRateGroup"
              name="rackRateGroup"
              value={formData.rackRateGroup}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Enter rack rate"
              required
            />
          </div>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          {isEditing ? "Update Rate" : "Submit"}
        </button>
      </form>

      {rates.length > 0 && (
        <div className="mt-6">
          <h3 className="text-xl font-bold mb-4">Rates List</h3>
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2">Room Type</th>
                <th className="border border-gray-300 px-4 py-2">Rack Rate Sing</th>
                <th className="border border-gray-300 px-4 py-2">Rack Rate Dbl</th>
                <th className="border border-gray-300 px-4 py-2">Rack Rate Grp</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {rates.map((rate, index) => {
                const room = roomTypes.find((room) => room._id === rate.roomType);
                return (
                  <tr key={index}>
                    <td className="border border-gray-300 px-4 py-2">{room ? room.name : rate.roomType}</td>
                    <td className="border border-gray-300 px-4 py-2">R{rate.rackRateSingle}</td>
                    <td className="border border-gray-300 px-4 py-2">R{rate.rackRateDouble}</td>
                    <td className="border border-gray-300 px-4 py-2">R{rate.rackRateGroup}</td>
                    <td className="border border-gray-300 px-4 py-3 flex justify-around">
                      <button
                        onClick={() => handleView(index)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        <FaEye />
                      </button>
                      <button
                        onClick={() => handleEdit(index)}
                        className="text-green-500 hover:text-green-700"
                      >
                        <FaEdit />
                      </button>
                      <button
                        onClick={() => handleDelete(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {viewRate && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full border border-gray-300">
            <h3 className="text-2xl font-bold mb-6 text-center border-b pb-4">Rate Details</h3>
            <div className="grid grid-cols-2 gap-4">
              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>Room Type:</strong>
                </p>
                <p className="text-gray-600">{viewRate.roomType}</p>
              </div>

              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>Date:</strong>
                </p>
                <p className="text-gray-600">{formatDate(viewRate.date)}</p>
              </div>

              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>STO Rate Sing:</strong>
                </p>
                <p className="text-gray-600">{viewRate.stoRateSingle}</p>
              </div>

              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>Rack Rate Sing:</strong>
                </p>
                <p className="text-gray-600">{viewRate.rackRateSingle}</p>
              </div>
              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>STO Rate Dbl:</strong>
                </p>
                <p className="text-gray-600">{viewRate.stoRateDouble}</p>
              </div>

              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>Rack Rate Dbl:</strong>
                </p>
                <p className="text-gray-600">{viewRate.rackRateDouble}</p>
              </div>
              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>STO Rate Grp:</strong>
                </p>
                <p className="text-gray-600">{viewRate.stoRateGroup}</p>
              </div>

              <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                  <strong>Rack Rate Grp:</strong>
                </p>
                <p className="text-gray-600">{viewRate.rackRateGroup}</p>
              </div>
            </div>

            <div className="mt-8 flex justify-end">
              <button
                onClick={closeModal}
                className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition border border-red-700"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RateForm;