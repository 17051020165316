import React from 'react';
import HotelList from './HotelList';

function HotelDashboard() {
  return (
    <div className="space-y-4">
      <h1 className="text-2xl font-bold">Dashboard</h1>
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <div className="rounded-lg bg-white p-4 shadow">
          <h2 className="text-sm font-medium text-gray-500">Total Hotels</h2>
          <p className="mt-2 text-3xl font-bold">25</p>
        </div>
        <div className="rounded-lg bg-white p-4 shadow">
          <h2 className="text-sm font-medium text-gray-500">Average Rating</h2>
          <p className="mt-2 text-3xl font-bold">4.5</p>
        </div>
        <div className="rounded-lg bg-white p-4 shadow">
          <h2 className="text-sm font-medium text-gray-500">Total Bookings</h2>
          <p className="mt-2 text-3xl font-bold">1,234</p>
        </div>
        <div className="rounded-lg bg-white p-4 shadow">
          <h2 className="text-sm font-medium text-gray-500">Revenue</h2>
          <p className="mt-2 text-3xl font-bold">$123,456</p>
        </div>
      </div>
      {/* <HotelList /> */}
    </div>
  );
}

export default HotelDashboard;
