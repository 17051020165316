import React from "react";
import Navbar from "../components/Navbars/Navbar";
import { Outlet} from "react-router-dom";
import Footer from "../components/Footers/Footer";

const Layout = () => {

  return (
    <>
      <div className="pt-16 md:pt-28">
        <Navbar/>
        <Outlet />        
        <Footer />
      </div>            
    </>
  );
};

export default Layout;
