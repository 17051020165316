import React, { useState, useEffect } from "react";
import axios from "axios";
import RoomTypeForm from "../../components/admin/RoomTypeForm";

const RoomTypesManager = () => {
  
  return (
    <div>
      <RoomTypeForm/>
    </div>
  );
};

export default RoomTypesManager;
