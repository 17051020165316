import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Layout from "./pages/Layout";
import Home from "./pages/home/Home";
import Packages from "./pages/Packages";
import Destinations from "./pages/Destinations";
import Services from "./pages/Services";
import Insurance from "./pages/Insurance";
import Contact from "./pages/Contact";
import Specials from "./pages/Specials";
import TripPlan from "./pages/TripPlan";
import Booking from "./pages/Booking";
import Hotels from "./pages/hotel/Hotel";
import Cruise from "./pages/Cruise";
import Cars from "./pages/Cars";
import Details from "./pages/Details";
import SearchResultsList from "./pages/SearchResultsList";
import List from "./pages/list/List";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import AdminDashboard from "./pages/AdminDashBoard/TempAdminDashboard";
import PackageTable from './pages/AdminDashBoard/PackageTable';
import CreateHotel from './pages/AdminDashBoard/CreateHotel';
import HotelManagement from './pages/AdminDashBoard/HotelManagement';
import HotelManagementLayout from "./pages/AdminDashBoard/HotelManagementLayout";
import ViewPackage from './pages/AdminDashBoard/ViewPackage';
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Package from "./components/Package/Package";
import Destination from "./components/Destination/Destination";
import Special from "./components/Special/Special";
import Service from "./components/Service/Service";
import HotelAdmin from "./pages/AdminDashBoard/Hotel";
import Dashboard from "./pages/AdminDashBoard/Dashboard";
import Hotel from "./pages/AdminDashBoard/Hotel";
import PackageAdmin from "./pages/AdminDashBoard/PackageAdmin";
import AmenitiesManager from "./pages/AdminDashBoard/AmenitiesManager";
import RoomTypesManager from "./pages/AdminDashBoard/RoomTypesManager";
import ActivitiesManager from "./pages/AdminDashBoard/ActivitiesManager";
import RatesManager from "./pages/AdminDashBoard/RatesManager";
import RateTypeManager from "./pages/AdminDashBoard/RateTypeManager";
import PoliciesManager from "./pages/AdminDashBoard/PoliciesManager";
import AnalyticsManager from "./pages/AdminDashBoard/AnalyticsManager";
import ViewHotel from "./pages/AdminDashBoard/ViewHotel";
import EditHotel from "./pages/AdminDashBoard/EditHotel";
import RoomManager from "./pages/AdminDashBoard/RoomManager";
import ViewRoomType from "./pages/AdminDashBoard/ViewRoomType";
import EditRoomType from "./pages/AdminDashBoard/EditRoomType";
import ViewAmenity from "./pages/AdminDashBoard/ViewAmenity";
import EditAmenity from "./pages/AdminDashBoard/EditAmenity";
import EditPackage from "./pages/AdminDashBoard/EditPackage";
import PackageManager from "./pages/AdminDashBoard/PackageManager";
import HolidayPackagesManager from "./pages/AdminDashBoard/HolidayPackagesManager";

const App = () => {

  React.useEffect(() => {
    AOS.init({
      offset: 100,
      duration: 900,
      easing: "ease-in-sine",
      delay: 100,
    });
    AOS.refresh();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/Packages" element={<Package/>}/>
            <Route path="/Destinations" element={<Destination/>}/>
            <Route path="/Specials" element={<Special/>}/>
            <Route path="/Insurance" element={<Insurance/>}/>
            <Route path="/Services" element={<Service/>}/>
            <Route path="/TripPlan" element={<TripPlan/>}/>
            <Route path="/Contact" element={<Contact/>}/>
            <Route path="/Booking/:id" element={<Booking/>}/>
            <Route path="/hotels" element={<List/>}/>
            <Route path="/Cruise" element={<Cruise/>}/>
            <Route path="/Cars" element={<Cars/>}/>
            <Route path="/hotels/:id" element={<Hotel/>}/>
            <Route path="/Details/:id" element={<Details/>}/>
            <Route path="/SearchResultsList" element={<SearchResultsList/>}/>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/addhotel" element={<CreateHotel />} />
            {/* Protected Admin Route */}
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />                        
          </Route>
          <Route element={<HotelManagementLayout />}>
            <Route path="/dashboard" 
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }  
            />
            <Route path="/hotel/list" 
              element={
                <ProtectedRoute>
                  <Hotel />
                </ProtectedRoute>
              }  
            />
            <Route path="/createhotel" 
              element={
                <ProtectedRoute>
                  <CreateHotel />
                </ProtectedRoute>
              }  
            />
            <Route path="/viewhotel/:id" 
              element={
                <ProtectedRoute>
                  <ViewHotel />
                </ProtectedRoute>
              }  
            />
            <Route path="/edithotel/:id" 
              element={
                <ProtectedRoute>
                  <EditHotel />
                </ProtectedRoute>
              }  
            />
            <Route path="/rooms" 
              element={
                <ProtectedRoute>
                  <RoomManager />
                </ProtectedRoute>
              }  
            />
            <Route path="/viewroomtype/:id" 
              element={
                <ProtectedRoute>
                  <ViewRoomType />
                </ProtectedRoute>
              }  
            />
            <Route path="/editroomtype/:id" 
              element={
                <ProtectedRoute>
                  <EditRoomType />
                </ProtectedRoute>
              }  
            />
            <Route path="/viewamenity/:id" 
              element={
                <ProtectedRoute>
                  <ViewAmenity />
                </ProtectedRoute>
              }  
            />
            <Route path="/editamenity/:id" 
              element={
                <ProtectedRoute>
                  <EditAmenity />
                </ProtectedRoute>
              }  
            />
            <Route path="/roomtypes" 
              element={
                <ProtectedRoute>
                  <RoomTypesManager />
                </ProtectedRoute>
              }  
            />
            <Route path="/amenities" 
              element={
                <ProtectedRoute>
                  <AmenitiesManager />
                </ProtectedRoute>
              }  
            />
            <Route path="/activities" 
              element={
                <ProtectedRoute>
                  <ActivitiesManager />
                </ProtectedRoute>
              }  
            />
            <Route path="/rates" 
              element={
                <ProtectedRoute>
                  <RatesManager />
                </ProtectedRoute>
              }  
            />
            <Route path="/ratetypes" 
              element={
                <ProtectedRoute>
                  <RateTypeManager />
                </ProtectedRoute>
              }  
            />
            <Route path="/holiday-packages" 
              element={
                <ProtectedRoute>
                  <PackageTable />
                </ProtectedRoute>
              }  
            />
            <Route path="/packages/create" 
              element={
                <ProtectedRoute>
                  <HolidayPackagesManager />
                </ProtectedRoute>
              }  
            />
            <Route path="/packages/creates" 
              element={
                <ProtectedRoute>
                  <PackageManager />
                </ProtectedRoute>
              }  
            />
            <Route 
              path="/packages/edit/:id" 
              element={
                <ProtectedRoute>
                  <EditPackage />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/packages/view/:id" 
              element={
                <ProtectedRoute>
                  <ViewPackage />
                </ProtectedRoute>
              } 
            />
            <Route path="/policies" 
              element={
                <ProtectedRoute>
                  <PoliciesManager />
                </ProtectedRoute>
              }  
            />
            <Route path="/analytics" 
              element={
                <ProtectedRoute>
                  <AnalyticsManager />
                </ProtectedRoute>
              }  
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
