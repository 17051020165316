import React, { useState } from "react";
import AmenityForm from "../../components/admin/AminityForm";

const AmenitiesManager = () => {
  
  return (
    <div>
      <AmenityForm/>
    </div>
  );
};

export default AmenitiesManager;
