import React, { useEffect } from "react";
import { MdAdminPanelSettings } from "react-icons/md";
import Logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { MobileLinks } from "./Navbar";

const ResponsiveMenu = ({ showMenu, setShowMenu }) => {
  const isAdmin = localStorage.getItem("isAdmin");

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => document.body.classList.remove("overflow-hidden");
  }, [showMenu]);

  const handleOverlayClick = () => setShowMenu(false);

  return (
    <>
      {showMenu && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-30"
          onClick={handleOverlayClick}
          aria-hidden="true"
        />
      )}

      <div
        className={`fixed top-0 bottom-0 z-40 w-[75%] max-w-[300px] bg-gradient-to-r from-purple-500 via-blue-500 to-green-500 text-white transition-transform duration-300 ease-in-out ${
          showMenu ? "translate-x-0" : "-translate-x-full"
        } shadow-lg`}
      >
        <div className="flex items-center p-4">
          <Link
            to="/"
            onClick={() => {
              window.scrollTo(0, 0);
              setShowMenu(false);
            }}
          >
            <img src={Logo} alt="Logo" className="w-32 h-10" />
          </Link>
        </div>
        <nav className="mt-4">
          <ul className="space-y-4">
            {MobileLinks.map((data) => (
              <li key={data.name} className="px-4 py-2 hover:bg-gray-700">
                {data.link.startsWith("http") ? (
                  <a
                    href={data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2"
                  >
                    <data.icon className="w-6 h-6" />
                    {data.name}
                  </a>
                ) : (
                  <Link
                    to={data.link}
                    onClick={() => setShowMenu(false)}
                    className="flex items-center gap-2"
                  >
                    <data.icon className="w-6 h-6" />
                    {data.name}
                  </Link>
                )}
              </li>
            ))}
            {isAdmin && (
              <li className="px-4 py-2 hover:bg-gray-700">
                <Link
                  to="/dashboard"
                  onClick={() => setShowMenu(false)}
                  className="flex items-center gap-2"
                >
                  <MdAdminPanelSettings className="w-6 h-6" />
                  Admin
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default ResponsiveMenu;