import React from 'react';
import { Outlet } from 'react-router-dom';
import { SidebarProvider, useSidebar } from "../../context/SidebarContext";
import { AdminSidebar } from "../../components/admin/AdminSidebar";
import Navbar from '../../components/Navbars/Navbar';
import AdminFooter from '../../components/Footers/AdminFooter';

const LayoutContent = () => {
  const { isOpen } = useSidebar(); // Get sidebar state

  return (
    <div className="flex min-h-screen">
      <AdminSidebar />
      {/* Adjust the main content width dynamically */}
      <div
        className={`flex-1 flex flex-col transition-all duration-300 ${
          isOpen ? 'ml-64' : 'ml-16'
        }`}
      >
        <Navbar hideResponsiveMenu={true}/>
        <main className="flex-1 p-6 pt-20 sm:pt-28">
          <Outlet />
        </main>
        <AdminFooter />
      </div>
    </div>
  );
};

const HotelManagementLayout = () => {
  return (
    <SidebarProvider>
      <LayoutContent />
    </SidebarProvider>
  );
};

export default HotelManagementLayout;
