import React from "react";
import RateTypeForm from "../../components/admin/RateTypeForm";

const RateTypeManager = () => {
    return (
      <div>
        <RateTypeForm/>
      </div>
    );
  };
  
  export default RateTypeManager;