import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const ViewRoomType = () => {
  const { id } = useParams();
  const [roomType, setRoomType] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchRoomType = async () => {
      try {
        const response = await axios.get(`${apiUrl}/roomtypes/${id}`);
        setRoomType(response.data);
      } catch (err) {
        console.error("Error fetching room type:", err);
      }
    };

    fetchRoomType();
  }, [id]);

  if (!roomType) {
    return <div className="justify-center items-center">Loading...</div>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">{roomType.name}</h2>
      <p className="mb-2"><strong>Description:</strong> {roomType.description}</p>
      <p><strong>Capacity:</strong> {roomType.capacity}</p>
      <p className="my-2"><strong>Base Rate:</strong> R{roomType.baseRate}</p>
      <p><strong>Amenities:</strong> {roomType.amenities.join(", ")}</p>
      <div className="mt-2">
        <h3 className="font-bold">Photos:</h3>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {roomType.photos.map((photo, index) => (
            <img key={index} src={photo} alt={`Room photo ${index + 1}`} className="rounded-lg" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewRoomType;
