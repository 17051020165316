import React, { useState, useEffect } from "react";
import { FaRegBuilding, FaEnvelope, FaPhone, FaMapMarkerAlt, FaStar, FaImages } from "react-icons/fa";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import axios from "axios";

const ViewHotel = () => {
  const [hotelData, setHotelData] = useState({
    name: "",
    type: "",
    address: "",
    country: "",
    city: "",
    postalCode: "",
    email: "",
    phone: "",
    photos: [],
    desc: "",
    rating: "",
  });

  const { id } = useParams(); // Get the hotel ID from the URL
  const token = localStorage.getItem("access_token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchHotelData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/hotels/${id}`);
        setHotelData(response.data);
      } catch (error) {
        console.error("Error fetching hotel data:", error);
        toast.error("Failed to fetch hotel data");
      }
    };

    fetchHotelData();
  }, [id, apiUrl]);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Hotel Details</h2>
      <div className="space-y-4">
        {/* Name */}
        <div>
          <label className="block font-medium text-gray-700">Hotel Name</label>
          <div className="flex items-center border rounded-lg p-2">
            <FaRegBuilding className="text-gray-500 mr-2" />
            <span className="flex-1">{hotelData.name}</span>
          </div>
        </div>

        {/* Type */}
        <div>
          <label className="block font-medium text-gray-700">Hotel Type</label>
          <div className="border rounded-lg p-2">
            <span>{hotelData.type}</span>
          </div>
        </div>

        {/* Address */}
        <div>
          <label className="block font-medium text-gray-700">Address</label>
          <div className="flex items-center border rounded-lg p-2">
            <FaMapMarkerAlt className="text-gray-500 mr-2" />
            <span className="flex-1">{hotelData.address}</span>
          </div>
        </div>

        {/* Country and City */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block font-medium text-gray-700">Country</label>
            <div className="border rounded-lg p-2">
              <span>{hotelData.country}</span>
            </div>
          </div>
          <div>
            <label className="block font-medium text-gray-700">City</label>
            <div className="border rounded-lg p-2">
              <span>{hotelData.city}</span>
            </div>
          </div>
        </div>

        {/* Postal Code */}
        <div>
          <label className="block font-medium text-gray-700">Postal Code</label>
          <div className="border rounded-lg p-2">
            <span>{hotelData.postalCode}</span>
          </div>
        </div>

        {/* Email and Phone */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block font-medium text-gray-700">Email</label>
            <div className="flex items-center border rounded-lg p-2">
              <FaEnvelope className="text-gray-500 mr-2" />
              <span className="flex-1">{hotelData.email}</span>
            </div>
          </div>
          <div>
            <label className="block font-medium text-gray-700">Phone</label>
            <div className="flex items-center border rounded-lg p-2">
              <FaPhone className="text-gray-500 mr-2" />
              <span className="flex-1">{hotelData.phone}</span>
            </div>
          </div>
        </div>

        {/* Photos */}
        <div>
          <label className="block font-medium text-gray-700">Photos</label>
          {hotelData.photos.length > 0 && (
            <div className="mt-2 grid grid-cols-4 gap-2">
              {hotelData.photos.map((photo, index) => (
                <img
                  key={index}
                  src={photo}
                  alt={`Hotel ${index + 1}`}
                  className="h-20 w-20 object-cover rounded-md"
                />
              ))}
            </div>
          )}
        </div>

        {/* Rating */}
        <div>
          <label className="block font-medium text-gray-700">Rating</label>
          <div className="flex items-center border rounded-lg p-2">
            <FaStar className="text-gray-500 mr-2" />
            <span className="flex-1">{hotelData.rating}</span>
          </div>
        </div>

        {/* Description */}
        <div>
          <label className="block font-medium text-gray-700">Description</label>
          <div className="border rounded-lg p-2 min-h-[100px]">
            <p>{hotelData.desc}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewHotel;