import React from "react";
import HotelDashboard from "../../components/admin/HotelDashboard";

const Dashboard = () => {

  return (
    <div>
      <HotelDashboard />
    </div>
  );
};

export default Dashboard;