import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ViewAmenity = () => {
  const { id } = useParams(); // Get the amenityId from the route
  const [amenity, setAmenity] = useState(null);
  const token = localStorage.getItem("access_token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAmenity = async () => {
      try {
        const response = await axios.get(`${apiUrl}/amenities/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAmenity(response.data);
      } catch (error) {
        console.error("Error fetching amenity:", error);
        toast.error("Failed to load amenity details.");
      }
    };

    fetchAmenity();
  }, [id, apiUrl, token]);

  if (!amenity) {
    return <p>Loading amenity details...</p>;
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">View Amenity</h2>
      <div>
        <p>
          <strong>Name:</strong> {amenity.name}
        </p>
        <p className="mt-1">
          <strong>Description:</strong> {amenity.description || "N/A"}
        </p>
        <button
          onClick={() => navigate(-1)} // Go back to the previous page
          className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default ViewAmenity;
