import React from "react";
import { useParams } from "react-router-dom";
import CreateHotelForm from "../../components/admin/CreateHotelForm";

const CreateHotel = () => {

  return (
    <div>
      <CreateHotelForm />
    </div>
  );
};

export default CreateHotel;