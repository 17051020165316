import React, { useState, useEffect } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { MdAddPhotoAlternate } from "react-icons/md";

const RoomTypeForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    hotel: "",
    name: "",
    description: "",
    capacity: "",
    baseRate: "",
    amenities: [],
    photos: [],
  });

  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const hotelId = localStorage.getItem("hotelId");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const apiUrl = process.env.REACT_APP_API_URL;

  const [roomTypes, setRoomTypes] = useState([]);
  const [newAmenity, setNewAmenity] = useState("");

  // Fetch room types when the component mounts
  useEffect(() => {
    const fetchRoomTypes = async () => {
      try {
        const response = await axios.get(`${apiUrl}/roomtypes?hotelId=${hotelId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRoomTypes(response.data);
      } catch (error) {
        console.error("Error fetching room types:", error);
        toast.error("Failed to load room types.");
      }
    };

    fetchRoomTypes();
  }, [token, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddAmenity = () => {
    if (newAmenity.trim()) {
      setFormData({ ...formData, amenities: [...formData.amenities, newAmenity] });
      setNewAmenity("");
    }
  };

  const handleRemoveAmenity = (index) => {
    const updatedAmenities = formData.amenities.filter((_, i) => i !== index);
    setFormData({ ...formData, amenities: updatedAmenities });
  };

  const handlePhotoUpload = (e) => {
    const files = Array.from(e.target.files);
    if (formData.photos.length + files.length > 8) {
      alert("You can upload a maximum of 8 photos.");
      return;
    }
    const photoUrls = files.map((file) => URL.createObjectURL(file));
    setFormData({ ...formData, photos: [...formData.photos, ...photoUrls] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const originalAuthHeader = axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["Authorization"];
  
    try {
      // Upload photos to Cloudinary
      const photoUrls = await Promise.all(
        formData.photos.map(async (photo) => {
          const fileBlob = await fetch(photo).then((res) => res.blob());
          const formData = new FormData();
          formData.append("file", fileBlob);
          formData.append("upload_preset", "upload");
  
          const uploadRes = await axios.post(
            "https://api.cloudinary.com/v1_1/dn8636eu9/image/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
  
          return uploadRes.data.url;
        })
      );
  
      // Restore Authorization header after uploads
      axios.defaults.headers.common["Authorization"] = originalAuthHeader;
  
      // Create payload with updated photo URLs
      const payload = { ...formData, photos: photoUrls, hotel: localStorage.getItem("hotelId") };
  
      // Send payload to your API
      const response = await axios.post(
        `${apiUrl}/roomtypes`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      toast.success("Room Type created successfully!");
      setFormData({
        hotel: "",
        name: "",
        description: "",
        capacity: "",
        baseRate: "",
        amenities: [],
        photos: [],
      });
  
      // Refresh room types
      const newResults = await axios.get(`${apiUrl}/roomtypes?hotelId=${hotelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRoomTypes(newResults.data);
    } catch (err) {
      console.error(err);
      toast.error("Failed to create hotel.");
    }
  };  

  const handleDelete = async (roomTypeId) => {
    if (window.confirm("Are you sure you want to delete this room type?")) {
      try {
        await axios.delete(`${apiUrl}/roomtypes/${roomTypeId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        const newResults = await axios.get(`${apiUrl}/roomtypes?hotelId=${hotelId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setRoomTypes(newResults.data);
  
        toast.success("Room type deleted successfully!");
      } catch (error) {
        console.error("Error deleting room type:", error);
        toast.error("Failed to delete room type. Please try again.");
      }
    }
  };  

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Add Room Type</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md: gap-4">    
            <div>
            <label htmlFor="name" className="block font-medium text-gray-700">
                Room Type Name
            </label>
            <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full border rounded-lg p-2 outline-none"
                placeholder="Enter room type name"
                required
            />
            </div>
            <div>
            <label htmlFor="capacity" className="block font-medium text-gray-700">
                Capacity
            </label>
            <input
                type="number"
                id="capacity"
                name="capacity"
                value={formData.capacity}
                onChange={handleChange}
                className="w-full border rounded-lg p-2 outline-none"
                placeholder="Enter capacity"
                required
            />
            </div>
            <div>
            <label htmlFor="baseRate" className="block font-medium text-gray-700">
                Base Rate
            </label>
            <input
                type="number"
                id="baseRate"
                name="baseRate"
                value={formData.baseRate}
                onChange={handleChange}
                className="w-full border rounded-lg p-2 outline-none"
                placeholder="Enter base rate"
                required
            />
            </div>
        </div>

        <div>
          <label htmlFor="description" className="block font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full border rounded-lg p-2 outline-none"
            placeholder="Enter description (optional)"
            rows="4"
          ></textarea>
        </div>               

        <div>
          <label className="block font-medium text-gray-700">Room Amenities</label>
          <div className="flex gap-2">
            <input
              type="text"
              value={newAmenity}
              onChange={(e) => setNewAmenity(e.target.value)}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Enter amenity"
            />
            <button
              type="button"
              onClick={handleAddAmenity}
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
            >
              Add
            </button>
          </div>
          <ul className="mt-2 space-y-1">
            {formData.amenities.map((amenity, index) => (
              <li key={index} className="flex justify-between items-center">
                <span>{amenity}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveAmenity(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <label className="block font-medium text-gray-700">Room Photos</label>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handlePhotoUpload}
            className="w-full border rounded-lg p-2 outline-none"
          />
          <div className="mt-2 flex flex-wrap gap-2">
            {formData.photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`Room photo ${index + 1}`}
                className="w-24 h-24 object-cover rounded-lg"
              />
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Submit
        </button>
      </form>

      {roomTypes.length > 0 && (
        <div className="mt-6">
          <h3 className="text-xl font-bold mb-4">Room Types List</h3>
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2">Name</th>
                <th className="border border-gray-300 px-4 py-2">Capacity</th>
                <th className="border border-gray-300 px-4 py-2">Base Rate</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {roomTypes.map((roomType, index) => (
                <tr key={index}>
                  <td className="border border-gray-300 px-4 py-2">{roomType.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{roomType.capacity}</td>
                  <td className="border border-gray-300 px-4 py-2">R{roomType.baseRate}</td>
                  <td className="border border-gray-300 px-4 py-3 flex justify-around">
                    <button
                      onClick={() => navigate(`/viewroomtype/${roomType._id}`)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FaEye />
                    </button>
                    <button
                      onClick={() => navigate(`/editroomtype/${roomType._id}`)}
                      className="text-green-500 hover:text-green-700"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDelete(roomType._id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RoomTypeForm;