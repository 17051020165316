import React, { useEffect, useState, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Hotel, Edit, Trash, Eye } from 'lucide-react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function HotelList() {
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const token = localStorage.getItem("access_token");
  const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;

  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchHotels = async () => {
    try {
      localStorage.removeItem("hotelId");      
  
      if (!token || !user) {
        setError("User not authenticated");
        setLoading(false);
        return;
      }
  
      const hotelIds = user.hotels.map((hotel) => hotel.$oid || hotel); // Extract hotel IDs
      const fetchedHotels = [];
  
      for (const id of hotelIds) {
        try {
          const response = await axios.get(`${apiUrl}/hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the headers
            },
          });
          fetchedHotels.push(response.data); // Add the hotel to the list
        } catch (err) {
          console.error(`Failed to fetch hotel with ID: ${id}`, err);
        }
      }

      setHotels(fetchedHotels); // Set all the fetched hotels
    } catch (err) {
      console.error(err);
      setError("Failed to fetch hotels");
    } finally {
      setLoading(false);
    }
  };  

  useEffect(() => {
    fetchHotels();
  }, []);

  const openModal = (hotel) => {
    setSelectedHotel(hotel);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedHotel(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${apiUrl}/hotels/${selectedHotel._id}`);
      toast.success('Hotel deleted successfully');
      fetchHotels(); // Refresh the list
    } catch (err) {
      console.error(err);
      toast.error('Failed to delete hotel. Please try again.');
    } finally {
      closeModal();
    }
  };

  const handleView = (id) => {
    localStorage.setItem("hotelId", id);
    navigate(`/viewhotel/${id}`);
  };

  const handleEdit = (id) => {
    localStorage.setItem("hotelId", id);
    navigate(`/edithotel/${id}`);
  };

  if (loading) {
    return <div>Loading hotels...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-bold">Hotels</h2>
        <Link to="/createhotel" className="rounded-md bg-blue-600 px-4 py-2 text-white hover:bg-blue-700">
          Add New Hotel
        </Link>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">City</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Rating</th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {hotels.map((hotel) => (
              <tr key={hotel._id}>
                <td className="whitespace-nowrap px-6 py-4">{hotel.name}</td>
                <td className="whitespace-nowrap px-6 py-4">{hotel.type}</td>
                <td className="whitespace-nowrap px-6 py-4">{hotel.city}</td>
                <td className="whitespace-nowrap px-6 py-4">{hotel.rating}</td>
                <td className="whitespace-nowrap px-6 py-4">
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => handleView(hotel._id)}
                      className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600"
                      title="View"
                    >
                      Manage
                      {/* <Eye className="h-5 w-5" /> */}
                    </button>
                    <button
                      onClick={() => handleEdit(hotel._id)}
                      className="text-blue-600 hover:text-blue-900"
                      title="Edit"
                    >
                      <Edit className="h-5 w-5" />
                    </button>
                    <button
                      onClick={() => openModal(hotel)}
                      className="text-red-600 hover:text-red-900"
                      title="Delete"
                    >
                      <Trash className="h-5 w-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Confirmation Modal */}
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    Confirm Deletion
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Are you sure you want to delete the hotel <strong>{selectedHotel?.name}</strong>? This action cannot
                      be undone.
                    </p>
                  </div>

                  <div className="mt-4 flex justify-end space-x-4">
                    <button
                      type="button"
                      className="rounded-md bg-gray-200 px-4 py-2 text-gray-700 hover:bg-gray-300"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="rounded-md bg-red-600 px-4 py-2 text-white hover:bg-red-700"
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default HotelList;
