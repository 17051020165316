import React, { useState, useEffect } from "react";
import { FaRegBuilding, FaEnvelope, FaPhone, FaMapMarkerAlt, FaStar, FaImages } from "react-icons/fa";
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";

const EditHotel = () => {
  const [formData, setFormData] = useState({
    name: "",
    type: "",
    address: "",
    country: "",
    city: "",
    postalCode: "",
    email: "",
    phone: "",
    photos: [],
    desc: "",
    rating: "",
  });

  const navigate = useNavigate();
  const { id } = useParams(); // Get the hotel ID from the URL
  const token = localStorage.getItem("access_token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchHotelData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/hotels/${id}`);
        setFormData(response.data);
      } catch (error) {
        console.error("Error fetching hotel data:", error);
        toast.error("Failed to fetch hotel data");
      }
    };

    fetchHotelData();
  }, [id, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 8);
    const photoURLs = files.map((file) => URL.createObjectURL(file));
    setFormData({ ...formData, photos: [...formData.photos, ...photoURLs] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const originalAuthHeader = axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["Authorization"];
  
    try {
      // Upload new photos to Cloudinary
      const newPhotoUrls = await Promise.all(
        formData.photos.filter(photo => photo.startsWith('blob:')).map(async (photo) => {
          const fileBlob = await fetch(photo).then((res) => res.blob());
          const formData = new FormData();
          formData.append("file", fileBlob);
          formData.append("upload_preset", "upload");
  
          const uploadRes = await axios.post(
            "https://api.cloudinary.com/v1_1/dn8636eu9/image/upload",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "X-Requested-With": "XMLHttpRequest",
              },
            }
          );
  
          return uploadRes.data.url;
        })
      );
  
      // Restore Authorization header after uploads
      axios.defaults.headers.common["Authorization"] = originalAuthHeader;
  
      // Create payload with updated photo URLs
      const payload = {
        ...formData,
        photos: [...formData.photos.filter(photo => !photo.startsWith('blob:')), ...newPhotoUrls]
      };
  
      // Send payload to your API
      const response = await axios.put(
        `${apiUrl}/hotels/${id}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      toast.success("Hotel updated successfully!");
      navigate("/dashboard"); // Navigate to dashboard after success
    } catch (err) {
      console.error(err);
      toast.error("Failed to update hotel.");
    }
  };  

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Edit Hotel</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Name */}
        <div>
          <label htmlFor="name" className="block font-medium text-gray-700">
            Hotel Name
          </label>
          <div className="flex items-center border rounded-lg p-2">
            <FaRegBuilding className="text-gray-500 mr-2" />
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="flex-1 outline-none"
              placeholder="Enter hotel name"
              required
            />
          </div>
        </div>

        {/* Type */}
        <div>
          <label htmlFor="type" className="block font-medium text-gray-700">
            Hotel Type
          </label>
          <input
            type="text"
            id="type"
            name="type"
            value={formData.type}
            onChange={handleChange}
            className="w-full border rounded-lg p-2 outline-none"
            placeholder="e.g., Hotel, Resort"
            required
          />
        </div>

        {/* Address */}
        <div>
          <label htmlFor="address" className="block font-medium text-gray-700">
            Address
          </label>
          <div className="flex items-center border rounded-lg p-2">
            <FaMapMarkerAlt className="text-gray-500 mr-2" />
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="flex-1 outline-none"
              placeholder="Enter address"
              required
            />
          </div>
        </div>

        {/* Country and City */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="country" className="block font-medium text-gray-700">
              Country
            </label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Enter country"
              required
            />
          </div>
          <div>
            <label htmlFor="city" className="block font-medium text-gray-700">
              City
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Enter city"
              required
            />
          </div>
        </div>

        {/* Postal Code */}
        <div>
          <label htmlFor="postalCode" className="block font-medium text-gray-700">
            Postal Code
          </label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            className="w-full border rounded-lg p-2 outline-none"
            placeholder="Enter postal code"
            required
          />
        </div>

        {/* Email and Phone */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="email" className="block font-medium text-gray-700">
              Email
            </label>
            <div className="flex items-center border rounded-lg p-2">
              <FaEnvelope className="text-gray-500 mr-2" />
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="flex-1 outline-none"
                placeholder="Enter email"
                required
              />
            </div>
          </div>
          <div>
            <label htmlFor="phone" className="block font-medium text-gray-700">
              Phone
            </label>
            <div className="flex items-center border rounded-lg p-2">
              <FaPhone className="text-gray-500 mr-2" />
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="flex-1 outline-none"
                placeholder="Enter phone number"
                required
              />
            </div>
          </div>
        </div>

        {/* Photos */}
        <div>
          <label htmlFor="photos" className="block font-medium text-gray-700">
            Photos (Max 8)
          </label>
          <div className="flex items-center border rounded-lg p-2">
            <FaImages className="text-gray-500 mr-2" />
            <input
              type="file"
              id="photos"
              name="photos"
              onChange={handlePhotoChange}
              className="flex-1 outline-none"
              multiple
              accept="image/*"
            />
          </div>
          {formData.photos.length > 0 && (
            <div className="mt-2 grid grid-cols-4 gap-2">
              {formData.photos.map((photo, index) => (
                <img
                  key={index}
                  src={photo}
                  alt={`Preview ${index + 1}`}
                  className="h-20 w-20 object-cover rounded-md"
                />
              ))}
            </div>
          )}
        </div>

        {/* Rating */}
        <div>
          <label htmlFor="rating" className="block font-medium text-gray-700">
            Rating (0-5)
          </label>
          <div className="flex items-center border rounded-lg p-2">
            <FaStar className="text-gray-500 mr-2" />
            <input
              type="number"
              id="rating"
              name="rating"
              value={formData.rating}
              onChange={handleChange}
              className="flex-1 outline-none"
              placeholder="Enter rating"
              min="0"
              max="5"
              required
            />
          </div>
        </div>

        {/* Description */}
        <div>
          <label htmlFor="desc" className="block font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="desc"
            name="desc"
            value={formData.desc}
            onChange={handleChange}
            className="w-full border rounded-lg p-2 outline-none"
            placeholder="Enter description"
            rows="4"
            required
          ></textarea>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Update Hotel
        </button>
      </form>
    </div>
  );
};

export default EditHotel;