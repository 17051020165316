import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EditAmenity = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const token = localStorage.getItem("access_token");
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAmenity = async () => {
      try {
        const response = await axios.get(`${apiUrl}/amenities/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(response.data);
        setFormData({
          name: response.data.name,
          description: response.data.description || "",
        });
      } catch (error) {
        console.error("Error fetching amenity:", error);
        toast.error("Failed to load amenity details.");
      }
    };

    fetchAmenity();
  }, [id, apiUrl, token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`${apiUrl}/amenities/${id}`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Amenity updated successfully!");
      navigate(-1); // Go back to the previous page
    } catch (error) {
      console.error("Error updating amenity:", error);
      toast.error("Failed to update amenity.");
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Edit Amenity</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block font-medium text-gray-700">
            Amenity Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-lg p-2 outline-none"
            placeholder="Enter amenity name"
            required
          />
        </div>
        <div>
          <label htmlFor="description" className="block font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-lg p-2 outline-none"
            placeholder="Enter description (optional)"
            rows="4"
          ></textarea>
        </div>
        <button
          type="submit"
          className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
        >
          Update
        </button>
        <button
          onClick={() => navigate(-1)} // Go back to the previous page
          type="button"
          className="ml-2 bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default EditAmenity;
