import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const apiUrl = "http://localhost:8800/api"; // Replace with your API's base URL

const RoomForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    maxPeople: "",
    roomNumbers: [], // Changed to an array for flexibility
    hotel: "",
    roomType: "",
    active: false,
    unavailableDates: [],
  });
  const [newDateRange, setNewDateRange] = useState({ start: "", end: "" });
  const [rooms, setRooms] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("access_token");

  useEffect(() => {
    fetchRooms();
    fetchHotels();
    fetchRoomTypes();
  }, []);

  const fetchRooms = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${apiUrl}/rooms`);
      setRooms(res.data);
    } catch (err) {
      toast.error("Failed to fetch rooms.");
    } finally {
      setLoading(false);
    }
  };

  const fetchHotels = async () => {
    try {
      const res = await axios.get(`${apiUrl}/hotels`);
      setHotels(res.data);
    } catch (err) {
      toast.error("Failed to fetch hotels.");
    }
  };

  const fetchRoomTypes = async () => {
    try {
      const res = await axios.get(`${apiUrl}/roomTypes`);
      setRoomTypes(res.data);
    } catch (err) {
      toast.error("Failed to fetch room types.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleRoomNumbersChange = (e) => {
    const values = e.target.value.split(",").map((num) => num.trim());
    setFormData({ ...formData, roomNumbers: values });
  };

  const handleAddDateRange = () => {
    if (newDateRange.start && newDateRange.end && newDateRange.start < newDateRange.end) {
      setFormData({
        ...formData,
        unavailableDates: [...formData.unavailableDates, newDateRange],
      });
      setNewDateRange({ start: "", end: "" });
    } else {
      toast.warn("Please enter a valid date range.");
    }
  };

  const handleRemoveDateRange = (index) => {
    setFormData({
      ...formData,
      unavailableDates: formData.unavailableDates.filter((_, i) => i !== index),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.title || !formData.maxPeople || !formData.hotel || !formData.roomType) {
      toast.warn("Please fill in all required fields.");
      return;
    }

    try {
      if (editingIndex !== null) {
        const roomToUpdate = rooms[editingIndex];
        await axios.put(`${apiUrl}/rooms/${roomToUpdate._id}`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        toast.success("Room updated successfully!");
      } else {
        const res = await axios.post(`${apiUrl}/rooms`, formData, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setRooms([...rooms, res.data]);
        toast.success("Room created successfully!");
      }

      fetchRooms();
      setEditingIndex(null);
      setFormData({
        title: "",
        maxPeople: "",
        roomNumbers: [],
        hotel: "",
        roomType: "",
        active: false,
        unavailableDates: [],
      });
    } catch (err) {
      toast.error("An error occurred while processing the request.");
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <ToastContainer autoClose={1000} newestOnTop closeOnClick pauseOnHover />
      <h2 className="text-2xl font-bold mb-4">{editingIndex !== null ? "Edit Room" : "Add Room"}</h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="title" className="block font-medium text-gray-700">
              Title
            </label>
            <input
              type="text"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter room title"
              required
            />
          </div>
          <div>
            <label htmlFor="maxPeople" className="block font-medium text-gray-700">
              Max People
            </label>
            <input
              type="number"
              id="maxPeople"
              name="maxPeople"
              value={formData.maxPeople}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter maximum number of people"
              required
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
            <div>
            <label htmlFor="hotel" className="block font-medium text-gray-700">
                Hotel
            </label>
            <select
                id="hotel"
                name="hotel"
                value={formData.hotel}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg p-2 outline-none"
                required
            >
                <option value="">Select Hotel</option>
                {hotels.map((hotel) => (
                <option key={hotel._id} value={hotel._id}>
                    {hotel.name}
                </option>
                ))}
            </select>
            </div>

            <div>
            <label htmlFor="roomType" className="block font-medium text-gray-700">
                Room Type
            </label>
            <select
                id="roomType"
                name="roomType"
                value={formData.roomType}
                onChange={handleChange}
                className="w-full border border-gray-300 rounded-lg p-2 outline-none"
                required
            >
                <option value="">Select Room Type</option>
                {roomTypes.map((roomType) => (
                <option key={roomType._id} value={roomType._id}>
                    {roomType.name}
                </option>
                ))}
            </select>
            </div>
        </div>
        <div>
          <label htmlFor="roomNumbers" className="block font-medium text-gray-700">
            Room Numbers (comma-separated)
          </label>
          <input
            type="text"
            id="roomNumbers"
            name="roomNumbers"
            value={formData.roomNumbers.join(", ")}
            onChange={handleRoomNumbersChange}
            className="w-full border border-gray-300 rounded-lg p-2 outline-none"
            placeholder="e.g., 101, 102, 103"
            required
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          {editingIndex !== null ? "Update Room" : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default RoomForm;