import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const EditRoomType = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState(null);
  const [newAmenity, setNewAmenity] = useState("");

  const token = localStorage.getItem("access_token");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchRoomType = async () => {
      try {
        const response = await axios.get(`${apiUrl}/roomtypes/${id}`);
        setFormData(response.data);
      } catch (err) {
        console.error("Error fetching room type:", err);
        toast.error("Failed to load room type.");
      }
    };

    fetchRoomType();
  }, [id, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleAddAmenity = () => {
    if (newAmenity.trim()) {
      setFormData({ ...formData, amenities: [...formData.amenities, newAmenity] });
      setNewAmenity("");
    }
  };

  const handleRemoveAmenity = (index) => {
    const updatedAmenities = formData.amenities.filter((_, i) => i !== index);
    setFormData({ ...formData, amenities: updatedAmenities });
  };

  const handlePhotoUpload = (e) => {
    const files = Array.from(e.target.files);
    if (formData.photos.length + files.length > 8) {
      alert("You can upload a maximum of 8 photos.");
      return;
    }
    const photoUrls = files.map((file) => URL.createObjectURL(file));
    setFormData({ ...formData, photos: [...formData.photos, ...photoUrls] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const originalAuthHeader = axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["Authorization"];

    try {
      // Upload photos to Cloudinary
      const photoUrls = await Promise.all(
        formData.photos.map(async (photo) => {
          if (photo.startsWith("blob:")) {
            const fileBlob = await fetch(photo).then((res) => res.blob());
            const formData = new FormData();
            formData.append("file", fileBlob);
            formData.append("upload_preset", "upload");

            const uploadRes = await axios.post(
              "https://api.cloudinary.com/v1_1/dn8636eu9/image/upload",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  "X-Requested-With": "XMLHttpRequest",
                },
              }
            );

            return uploadRes.data.url;
          }
          return photo;
        })
      );

      // Restore Authorization header after uploads
      axios.defaults.headers.common["Authorization"] = originalAuthHeader;

      // Create payload with updated photo URLs
      const payload = { ...formData, photos: photoUrls };

      // Update the room type
      await axios.put(`${apiUrl}/roomtypes/${id}`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      toast.success("Room Type updated successfully!");
      navigate("/roomtypes");
    } catch (err) {
      console.error(err);
      toast.error("Failed to update room type.");
    }
  };

  if (!formData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Edit Room Type</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md: gap-4">
          <div>
            <label htmlFor="name" className="block font-medium text-gray-700">
              Room Type Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              required
            />
          </div>
          <div>
            <label htmlFor="capacity" className="block font-medium text-gray-700">
              Capacity
            </label>
            <input
              type="number"
              id="capacity"
              name="capacity"
              value={formData.capacity}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              required
            />
          </div>
          <div>
            <label htmlFor="baseRate" className="block font-medium text-gray-700">
              Base Rate
            </label>
            <input
              type="number"
              id="baseRate"
              name="baseRate"
              value={formData.baseRate}
              onChange={handleChange}
              className="w-full border rounded-lg p-2 outline-none"
              required
            />
          </div>
        </div>

        <div>
          <label htmlFor="description" className="block font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full border rounded-lg p-2 outline-none"
            rows="4"
          ></textarea>
        </div>

        <div>
          <label className="block font-medium text-gray-700">Room Amenities</label>
          <div className="flex gap-2">
            <input
              type="text"
              value={newAmenity}
              onChange={(e) => setNewAmenity(e.target.value)}
              className="w-full border rounded-lg p-2 outline-none"
              placeholder="Enter amenity"
            />
            <button
              type="button"
              onClick={handleAddAmenity}
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
            >
              Add
            </button>
          </div>
          <ul className="mt-2 space-y-1">
            {formData.amenities.map((amenity, index) => (
              <li key={index} className="flex justify-between items-center">
                <span>{amenity}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveAmenity(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <label className="block font-medium text-gray-700">Room Photos</label>
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={handlePhotoUpload}
            className="w-full border rounded-lg p-2 outline-none"
          />
          <div className="mt-2 flex flex-wrap gap-2">
            {formData.photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`Room photo ${index + 1}`}
                className="w-24 h-24 object-cover rounded-lg"
              />
            ))}
          </div>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
};

export default EditRoomType;
