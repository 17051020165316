import React, { useState, useEffect } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RateTypeForm = () => {
  const [formData, setFormData] = useState({
    hotel: "",
    name: "",
    description: "",
    singleRate: "",
    doubleRate: "",
    groupRate: "",
  });
  const [rateTypes, setRateTypes] = useState([]);
  const [roomTypes, setRoomTypes] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null); // Tracks index of the item being edited
  const [viewDetails, setViewDetails] = useState(null); // Tracks rate type for viewing details
  const token = localStorage.getItem("access_token");
  const hotelId = localStorage.getItem("hotelId");
  const apiUrl = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`; 
  
  const rateTypeNames = [
    {
        name: "Rack Rate",
        description: "The standard published rate, typically the highest price."
    },
    {
        name: "STO Rate",
        description: "A special rate available only to staff members of a specific company, and for travel agencies to resell."
    },
    {
        name: "Corporate Rate",
        description: "A discounted rate offered to businesses for their employees' travel needs, often negotiated for multiple bookings."
    }, 
    {
        name: "Wholesale Rate",
        description: "A discounted rate offered to businesses or travel agencies for bulk purchases or multiple bookings, typically intended for resale to end customers."
    },  
    {
        name: "Package Rate",
        description: "Combined rate for multiple services."
    },
    {
        name: "Seasonal Rate",
        description: "Prices that vary based on the time of year."
    },
    {
        name: "Weekend Rate",
        description: "Special pricing for weekend stays."
    },
    {
        name: "Long-Term Rate",
        description: "Special rates for extended rentals."
    },
    {
        name: "Student Rate",
        description: "Discount for students with valid ID."
    },
    {
        name: "Senior Citizen Rate",
        description: "Discount for seniors."
    },
    {
        name: "Family Rate",
        description: "Pricing based on the number of adults and children."
    },
    {
        name: "Inside Cabin Rate",
        description: "Cost for a cabin without a window."
    },
    {
        name: "Ocean View Rate",
        description: "Cabin with a window or balcony."
    },
    {
        name: "Suite Rate",
        description: "Premium cabins with more amenities."
    },
    {
        name: "Balcony Rate",
        description: "Cabin with a private balcony."
    }
];

  const fetchRoomTypes = async () => {
    try {
      const response = await axios.get(`${apiUrl}/roomtypes?hotelId=${hotelId}`);
      setRoomTypes(response.data);
    } catch (error) {
      console.error('Error fetching room types:', error);
    }
  };

  const fetchRateTypes = async () => {
    try {
      const response = await axios.get(`${apiUrl}/ratetypes?hotelId=${hotelId}`);
      setRateTypes(response.data);
    } catch (error) {
      console.error("Error fetching rate types:", error);
      toast.error("Failed to load rate types.");
    }
  };

  // Fetch room and rate types
  useEffect(() => {    
    fetchRoomTypes();
    fetchRateTypes();
  }, [apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!formData.name || !formData.singleRate || !formData.doubleRate || !formData.groupRate) {
        toast.error("Please fill in all required fields.");
        return;
    }

    try {
      if (editingIndex === null) {
        // Add new rate type
        const payload = { ...formData, hotel: hotelId };
        await axios.post(`${apiUrl}/ratetypes`, payload);
        toast.success("Rate Type added successfully!");
      } else {
        // Update existing rate type
        const rateTypeId = rateTypes[editingIndex]._id;
        const payload = { ...formData };
        await axios.put(`${apiUrl}/ratetypes/${rateTypeId}`, payload);
        toast.success("Rate Type updated successfully!");
      }

      // Refresh the rate types list
      const response = await axios.get(`${apiUrl}/ratetypes?hotelId=${hotelId}`);
      setRateTypes(response.data);

      // Reset form and editing state
      setFormData({
        hotel: "",
        name: "",
        description: "",
        singleRate: "",
        doubleRate: "",
        groupRate: "",
        active: true,
      });
      setEditingIndex(null);
    } catch (error) {
      console.error("Error saving rate type:", error);
      toast.error("Failed to save rate type.");
    }
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setFormData(rateTypes[index]); // Pre-fill the form with the selected rate type's data
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setFormData({
      hotel: "",
      name: "",
      description: "",
      singleRate: "",
      doubleRate: "",
      groupRate: "",
      active: true,
    });
  };

  const handleDelete = async (index) => {
    const rateTypeId = rateTypes[index]._id;

    if (window.confirm("Are you sure you want to delete this rate type?")) {
      try {
        await axios.delete(`${apiUrl}/ratetypes/${rateTypeId}`);
        const response = await axios.get(`${apiUrl}/ratetypes?hotelId=${hotelId}`);
        setRateTypes(response.data);
        toast.success("Rate Type deleted successfully!");
      } catch (error) {
        console.error("Error deleting rate type:", error);
        toast.error("Failed to delete rate type.");
      }
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
        <ToastContainer 
        autoClose={1000}
        newestOnTop
        closeOnClick
        pauseOnHover
      />
      <h2 className="text-2xl font-bold mb-4">
        {editingIndex === null ? "Add Rate Type" : "Edit Rate Type"}
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
                <label htmlFor="roomType" className="block font-medium text-gray-700">
                    Room Type
                </label>
                <select
                    id="roomType"
                    name="roomType"
                    value={formData.roomType}
                    onChange={handleChange}
                    className="w-full border border-gray-300 rounded-lg p-2 outline-none"
                >
                    <option value="">Select a room type</option>
                    {roomTypes.map((roomType) => (
                    <option key={roomType._id} value={roomType._id}>
                        {roomType.name}
                    </option>
                    ))}
                </select>
            </div>

            <div>
                <label htmlFor="name" className="block font-medium text-gray-700">
                    Rate Type Name
                </label>
                <select
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => {
                        const selectedName = e.target.value;
                        const selectedRateType = rateTypeNames.find(
                          (rateType) => rateType.name === selectedName
                        );
                  
                        // Update the formData state with the selected rate name and its description
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          name: selectedName,
                          description: selectedRateType?.description || "", // Default to empty string if no description
                        }));
                      }}
                    className="w-full border border-gray-300 rounded-lg p-2 outline-none"
                >
                    <option value="">Select a rate name</option>
                    {rateTypeNames.map((rateName) => (
                    <option key={rateName.name} value={rateName.name}>
                        {rateName.name}
                    </option>
                    ))}
                </select>
            </div>
        </div>
        <div>
          <label htmlFor="description" className="block font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full border border-gray-300 rounded-lg p-2 outline-none"
            placeholder="Enter description (optional)"
            rows="4"
            readOnly 
          ></textarea>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label htmlFor="singleRate" className="block font-medium text-gray-700">
              Single Rate Amount
            </label>
            <input
              type="number"
              id="singleRate"
              name="singleRate"
              value={formData.singleRate}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter single rate"
              required
            />
          </div>
          <div>
            <label htmlFor="doubleRate" className="block font-medium text-gray-700">
              Double Rate Amount
            </label>
            <input
              type="number"
              id="doubleRate"
              name="doubleRate"
              value={formData.doubleRate}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter double rate"
              required
            />
          </div>
          <div>
            <label htmlFor="groupRate" className="block font-medium text-gray-700">
              Group Rate Amount
            </label>
            <input
              type="number"
              id="groupRate"
              name="groupRate"
              value={formData.groupRate}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter group rate"
              required
            />
          </div>
        </div>
        <div className="flex space-x-4">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
          >
            {editingIndex === null ? "Submit" : "Update"}
          </button>
          {editingIndex !== null && (
            <button
              type="button"
              onClick={handleCancelEdit}
              className="bg-gray-300 text-black px-4 py-2 rounded-lg hover:bg-gray-400 transition"
            >
              Cancel
            </button>
          )}
        </div>
      </form>

      {/* Display Rate Types */}
        {rateTypes.length > 0 && (
        <div className="mt-6">
          <h3 className="text-xl font-bold mb-4">Rate Types List</h3>
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2">Name</th>
                <th className="border border-gray-300 px-4 py-2">Room Type</th>
                <th className="border border-gray-300 px-4 py-2">Single Rate</th>
                <th className="border border-gray-300 px-4 py-2">Double Rate</th>
                <th className="border border-gray-300 px-4 py-2">Group Rate</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {rateTypes.map((rateType, index) => (
                <tr key={rateType._id}>
                  <td className="border border-gray-300 px-4 py-2">{rateType.name}</td>
                  <td className="border border-gray-300 px-4 py-2">
                    {roomTypes.find((room) => room._id === rateType.roomType)?.name || "N/A"}
                  </td>
                  <td className="border border-gray-300 px-4 py-2">R{rateType.singleRate}</td>
                  <td className="border border-gray-300 px-4 py-2">R{rateType.doubleRate}</td>
                  <td className="border border-gray-300 px-4 py-2">R{rateType.groupRate}</td>
                  <td className="border border-gray-300 px-4 py-2 flex space-x-4">
                    <button
                      onClick={() => setViewDetails(rateType)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FaEye />
                    </button>
                    <button
                      onClick={() => handleEdit(index)}
                      className="text-green-500 hover:text-green-700"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDelete(index)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Modal for Viewing Details */}
        {viewDetails && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full border border-gray-300">
            <h3 className="text-2xl font-bold mb-6 text-center border-b pb-4">Rate Type Details</h3>

            <div className="grid grid-cols-2 gap-4">
                <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                    <strong>Room Type:</strong>
                </p>
                <p className="text-gray-600">{roomTypes.find((room) => room._id === viewDetails.roomType)?.name || "N/A"}</p>
                </div>

                <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                    <strong>Name:</strong>
                </p>
                <p className="text-gray-600">{viewDetails.name}</p>
                </div>

                <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                    <strong>Single Rate Amount:</strong>
                </p>
                <p className="text-gray-600">{viewDetails.singleRate}</p>
                </div>

                <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                    <strong>Double Rate Amount:</strong>
                </p>
                <p className="text-gray-600">{viewDetails.doubleRate}</p>
                </div>

                <div className="border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                    <strong>Group Rate Amount:</strong>
                </p>
                <p className="text-gray-600">{viewDetails.groupRate}</p>
                </div>
            </div>

            <div className="mt-6 border p-4 rounded-lg">
                <p className="text-gray-700 font-medium">
                <strong>Description:</strong>
                </p>
                <p className="text-gray-600">{viewDetails.description || "N/A"}</p>
            </div>

            <div className="mt-8 flex justify-end">
                <button
                onClick={() => setViewDetails(null)}
                className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-red-600 transition border border-red-700"
                >
                Close
                </button>
            </div>
            </div>
        </div>
        )}
    </div>
  );
};

export default RateTypeForm;
