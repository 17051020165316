import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { IoLocationSharp } from "react-icons/io5";
import useFetch from "../../hooks/useFetch";

const PackageCard = ({
  _id,
  title,
  country,
  city,
  desc,
  price,
  type,
  photos,
}) => {

  const formattedPrice = new Intl.NumberFormat('en-ZA', {
    style: 'currency',
    currency: 'ZAR',
    minimumFractionDigits: 0,
  }).format(price);

  const { data, loading, error } = useFetch(`${process.env.REACT_APP_API_URL}/packages/${_id}`);
  const navigate = useNavigate();

  const handleViewDetails = (id) => {
    navigate(`/Details/${id}`);
  };

  const handleBook = (id) => {
    navigate(`/booking/${id}`, { state: { packageData: data } });
  };

  return (
    <>
      <div
        className="shadow-lg transition-all duration-500 hover:shadow-xl dark:bg-slate-950 dark:text-white cursor-pointer"
      >
        <div className="overflow-hidden">
          <img
            src={photos[0]}
            alt="No image"
            className="mx-auto h-[220px] w-full object-cover transition duration-700 hover:skew-x-2 hover:scale-110"
          />
        </div>

        <div className="space-y-2 p-3">
          <h1 className="line-clamp-1 font-bold text-xl">{title}</h1>
          <div className="flex items-center gap-2 opacity-70">
            <IoLocationSharp />
            <span className="mr-auto">{country}</span>
            <span className="text-1xl font-bold">{formattedPrice}</span>
          </div>
          <p className="line-clamp-2">{desc}</p>
          <div className="flex items-center justify-between border-t-2 py-3 !mt-3">
            <button
              onClick={() => handleViewDetails(_id)}
              className="bg-[#5072A7] w-full text-white hover:scale-105 px-4 py-2 rounded-md duration-200"
            >
              View Details
            </button>
            <button
              onClick={() => handleBook(_id)}
              className="bg-[#D18700] w-full text-white hover:scale-105 px-4 py-2 rounded-md duration-200 ml-2"
            >
              Book Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PackageCard;
