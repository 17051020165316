import React, { useState, useEffect } from "react";
import { FaEye, FaEdit, FaTrash } from "react-icons/fa";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AmenityForm = () => {
  const [formData, setFormData] = useState({
    hotel: "",
    name: "",
    description: "",
  });

  const [amenities, setAmenities] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const hotelId = localStorage.getItem("hotelId");
  const apiUrl = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  // Fetch amenities when the component mounts
  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        const response = await axios.get(`${apiUrl}/amenities?hotelId=${hotelId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAmenities(response.data);
      } catch (error) {
        console.error("Error fetching amenities:", error);
        toast.error("Failed to load amenities.");
      }
    };

    fetchAmenities();
  }, [token, apiUrl]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name) {
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      const payload = { ...formData, hotel: hotelId };
      await axios.post(`${apiUrl}/amenities`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Amenity added successfully!");
      setFormData({ hotel: "", name: "", description: "" });

      // Refresh amenities list
      const response = await axios.get(`${apiUrl}/amenities?hotelId=${hotelId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAmenities(response.data);
    } catch (error) {
      console.error("Error adding amenity:", error);
      toast.error("Failed to add amenity.");
    }
  };

  const handleDelete = async (amenityId) => {
    if (window.confirm("Are you sure you want to delete this amenity?")) {
      try {
        await axios.delete(`${apiUrl}/amenities/${amenityId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Refresh amenities list
        const response = await axios.get(`${apiUrl}/amenities?hotelId=${hotelId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAmenities(response.data);
        toast.success("Amenity deleted successfully!");
      } catch (error) {
        console.error("Error deleting amenity:", error);
        toast.error("Failed to delete amenity.");
      }
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Add Amenity</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> */}
          <div>
            <label htmlFor="name" className="block font-medium text-gray-700">
              Amenity Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter amenity name"
              required
            />
          </div>
          <div>
            <label htmlFor="description" className="block font-medium text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              className="w-full border border-gray-300 rounded-lg p-2 outline-none"
              placeholder="Enter description (optional)"
              rows="4"
            ></textarea>
          </div>
        {/* </div> */}
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
        >
          Submit
        </button>
      </form>

      {amenities.length > 0 && (
        <div className="mt-6">
          <h3 className="text-xl font-bold mb-4">Amenities List</h3>
          <table className="min-w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2">Name</th>
                <th className="border border-gray-300 px-4 py-2">Description</th>
                <th className="border border-gray-300 px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {amenities.map((amenity) => (
                <tr key={amenity._id}>
                  <td className="border border-gray-300 px-4 py-2">{amenity.name}</td>
                  <td className="border border-gray-300 px-4 py-2">{amenity.description || "N/A"}</td>
                  <td className="border border-gray-300 px-4 py-3 flex justify-around">
                    <button
                      onClick={() => navigate(`/viewamenity/${amenity._id}`)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FaEye />
                    </button>
                    <button
                      onClick={() => navigate(`/editamenity/${amenity._id}`)}
                      className="text-green-500 hover:text-green-700"
                    >
                      <FaEdit />
                    </button>
                    <button
                      onClick={() => handleDelete(amenity._id)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AmenityForm;
