import React from "react";
import CreatePackageForm from "../../components/admin/CreatePackageForm";

const PackageManager = () => {
    return (
      <div>
        <CreatePackageForm/>
      </div>
    );
  };
  
  export default PackageManager;