import React from "react";
import RateForm from "../../components/admin/RateForm";

const RatesManager = () => {
    return (
      <div>
        <RateForm/>
      </div>
    );
  };
  
  export default RatesManager;
  