import React from "react";

const PoliciesManager = () => {
  return (
    <div>
      <h2>Policies</h2>
      <p>Manage hotel policies here...</p>
    </div>
  );
};

export default PoliciesManager;
