import React from "react";
import RoomForm from "../../components/admin/RoomForm";

const RoomManager = () => {
    return (
      <div>
        <RoomForm/>
      </div>
    );
  };
  
  export default RoomManager;